import { flowRight as compose } from 'lodash';
import React from 'react';
import { graphql } from 'react-apollo';
import { Route, Switch, withRouter } from 'react-router-dom';
import { withAppointments } from '../apollo/stores/appointments';
import { createUserAppointment } from '../libs/graphql/mutations/user';
import { UserAppointments } from '../libs/graphql/queries/user';

import { AppointmentPrep, Manage } from './Manage';

const composeWithAppointmentsMutation = graphql(createUserAppointment, {
  name: 'createUserAppointment',
});

const composeWithAppointments = graphql(UserAppointments, {
  props: ({ data: { loading, error, getAppointmentsByUserId } }) => ({
    loading,
    error,
    getAppointmentsByUserId,
  }),
  options: props => ({
    variables: {
      userid: props.me.id,
    },
    fetchPolicy: 'cache-and-network',
  }),
});

const ProviderPrep = props => {
  return (
    <div className="main-dashboard page-provider-prep">
      <div className="main-dashboard-content">
        <Switch>
          <Route
            path="/provider-prep/appointments/:id/surveys"
            exact={true}
            render={() => (
              <AppointmentPrep me={props.me} history={props.history} />
            )}
          />
          <Route path="/" render={() => <Manage {...props} />} />
        </Switch>
      </div>
    </div>
  );
};

export default compose(
  withRouter,
  withAppointments,
  composeWithAppointmentsMutation,
  composeWithAppointments,
)(ProviderPrep);
