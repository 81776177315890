import React, { useEffect, useState, memo } from 'react';

// LIBS
import * as Unicons from '@iconscout/react-unicons';
import ReactTooltip from 'react-tooltip';

// UTILS
import { PRICING_SCALE_TOOLTIP } from '../../../utils/constants';

// ICONS
import {
  BiologicIcon,
  HelperIcon,
  OtherIcon,
  OverTheCounterIcon,
  PhototherapyIcon,
  PrescriptionTopicalIcon,
  SystemicsIcon,
  Topical,
} from '../../../assets/icons';

// APOLLO QUERIES
import { TreatmentOptionsAndMetadata } from '../../../libs/graphql/queries/data';
import { useQuery } from 'react-apollo';

const initialGroups = [
  {
    id: '2',
    name: 'Prescription Topical',
    icon: <PrescriptionTopicalIcon width={16} height={16} color={'#fff'} />,
    treatments: [],
  },
  {
    id: '4',
    name: 'Phototherapy',
    icon: <PhototherapyIcon width={16} height={16} color={'#fff'} />,
    treatments: [],
  },
  {
    id: '3',
    name: 'Systemics',
    icon: <SystemicsIcon width={16} height={16} color={'#fff'} />,
    treatments: [],
  },
  {
    id: '5',
    name: 'Biologic',
    icon: <BiologicIcon width={16} height={16} color={'#fff'} />,
    treatments: [],
  },
  {
    id: '0',
    name: 'Over the Counter',
    icon: <OverTheCounterIcon width={16} height={16} color={'#fff'} />,
    treatments: [],
  },
  {
    id: '6',
    name: 'Other',
    icon: <OtherIcon width={24} height={24} color={'#fff'} />,
    treatments: [],
  },
  {
    id: '1',
    name: 'No Treatment',
    icon: <OtherIcon width={24} height={24} color={'#fff'} />,
    treatments: [],
  },
];

const TreatmentsSelection = ({
  selectedTreatments = [],
  editable = false,
  onAddTreatment,
}) => {
  const { data: treatmentOptionsAndMetadata } = useQuery(
    TreatmentOptionsAndMetadata,
  );

  const [groups, setGroups] = useState(initialGroups);
  const [selectedGroupId, setSelectedGroupId] = useState(initialGroups[0].id);
  const [selectedTreatmentId, setSelectedTreatmentId] = useState(null);
  const [treatmentToShow, setTreatmentToShow] = useState(null);
  const [selectedBlock, setSelectedBlock] = useState('All');
  const [searchText, setSearchText] = useState('');
  const [filteredTreatments, setFilteredTreatments] = useState([]);

  const validateTreatmentOptions = (treatmentOptions, groupOptions) => {
    const newGroups = [...groups];

    newGroups.forEach((group, key) => {
      newGroups[key].treatments = treatmentOptions.filter(
        treatmentOption => treatmentOption.group_id === group.id,
      );
    });

    setGroups(newGroups);
  };

  useEffect(() => {
    if (treatmentOptionsAndMetadata?.availableTreatmentOptions) {
      validateTreatmentOptions(
        treatmentOptionsAndMetadata.availableTreatmentOptions,
        treatmentOptionsAndMetadata.availableTreatmentOptionsGroups,
      );
    }
  }, [treatmentOptionsAndMetadata]);

  const onSearchChange = e => {
    const searchText = e.target.value;

    setSearchText(searchText);
    if (!searchText) {
      setFilteredTreatments([]);
      return;
    }

    const filtered =
      treatmentOptionsAndMetadata.availableTreatmentOptions.filter(
        treatment => {
          return treatment.name
            .toLowerCase()
            .includes(searchText.toLowerCase());
        },
      );
    setFilteredTreatments(filtered);
  };

  const clearSearch = () => {
    setSearchText('');
    setFilteredTreatments([]);
  };

  const onSelectTreatment = id => {
    setSelectedTreatmentId(id);
    const treatment =
      treatmentOptionsAndMetadata.availableTreatmentOptions.find(
        t => t.id === id,
      );
    if (treatment) {
      const metadata =
        treatmentOptionsAndMetadata.availableTreatmentOptionsMetadata.find(
          m => m.id === treatment.metadata_id,
        );
      if (metadata) {
        treatment.metadata = metadata;
      }
      setTreatmentToShow(treatment);
    }
  };

  const onToggleTreatment = treatment => {
    if (onAddTreatment) {
      onAddTreatment(treatment);
    }
  };

  const getCostLabel = cost => {
    if (cost === 0 || cost === null) {
      return '0';
    }
    return Array(cost).fill('$').join('');
  };

  const onCloseTreatmentDetails = () => {
    setTreatmentToShow(null);
    setSelectedTreatmentId(null);
  };

  return (
    <div className="treatments">
      <ReactTooltip
        type="light"
        className={'help-tooltip'}
        multiline={true}
        place="top"
        html={true}
        clickable
        key={selectedTreatmentId || 'tooltip'}
      />
      <div className="treatment-options">
        <div className="search-bar">
          {searchText ? (
            <label>
              <Unicons.UilTimes
                size="16"
                className="search-icon"
                onClick={clearSearch}
              />
            </label>
          ) : (
            <label htmlFor="treatmetsSearch">
              <Unicons.UilSearch size="16" className="search-icon" />
            </label>
          )}
          <input
            type="text"
            placeholder="Search treatments"
            className="search-input"
            id="treatmetsSearch"
            value={searchText}
            onChange={onSearchChange}
          />
        </div>

        <div
          className={`card collapsible ${
            selectedBlock === 'Added' ? 'active' : ''
          }`}>
          <div
            className="card-header"
            onClick={() => setSelectedBlock('Added')}>
            <h3>Added ({selectedTreatments.length})</h3>
            <div className="card-header-button">
              <Unicons.UilAngleDown size="24" />
            </div>
          </div>
          <div className="card-content">
            <div className="treatment-list">
              {groups.map(group => {
                return group.treatments.map(treatment => {
                  if (!selectedTreatments.includes(treatment.id)) {
                    return null;
                  }
                  return (
                    <div
                      className={`treatment-item ${
                        treatment.id === selectedTreatmentId ? 'active' : ''
                      }`}
                      key={treatment.id}>
                      <div
                        className="treatment-item-title"
                        onClick={() => onSelectTreatment(treatment.id)}>
                        <div className="question-button">
                          <Unicons.UilQuestion size="16" />
                        </div>
                        <div className="treatment-item-title">
                          <p>
                            <span>{treatment.name}</span>
                            <span> - </span>
                            <span className="subtitle">in {group.name}</span>
                          </p>
                        </div>
                        {editable && (
                          <div
                            className={`treatment-add-button ${
                              selectedTreatments.includes(treatment.id)
                                ? 'active'
                                : ''
                            }`}
                            onClick={e => {
                              e.stopPropagation();
                              onToggleTreatment(treatment);
                            }}>
                            {selectedTreatments.includes(treatment.id) ? (
                              <Unicons.UilCheck size="16" />
                            ) : (
                              <Unicons.UilPlus size="16" />
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  );
                });
              })}
            </div>
          </div>
        </div>

        <div
          className={`card collapsible ${
            selectedBlock === 'All' ? 'active' : ''
          }`}>
          <div className="card-header" onClick={() => setSelectedBlock('All')}>
            <h3>{searchText ? `Search "${searchText}"` : 'All treatments'}</h3>
            <div className="card-header-button">
              <Unicons.UilAngleDown size="24" />
            </div>
          </div>
          {!searchText && (
            <div className="card-content card-content-treatments">
              {groups.map(group => (
                <div
                  className={`treatment-group ${
                    selectedGroupId === group.id ? 'active' : ''
                  }`}
                  key={group.id}>
                  <div
                    className="group-header"
                    onClick={() => setSelectedGroupId(group.id)}>
                    <div className="group-header-title">
                      <h4>{group.name}</h4>
                    </div>
                    <div className="group-header-button">
                      <Unicons.UilAngleDown size="24" />
                    </div>
                  </div>
                  <div className="treatment-list">
                    {group.treatments.map(treatment => (
                      <div
                        className={`treatment-item ${
                          treatment.id === selectedTreatmentId ? 'active' : ''
                        }`}
                        key={treatment.id}>
                        <div
                          className="treatment-item-title"
                          onClick={() => onSelectTreatment(treatment.id)}>
                          <div className="question-button">
                            <Unicons.UilQuestion size="16" />
                          </div>
                          <div className="treatment-item-title">
                            <span>{treatment.name}</span>
                          </div>
                          {editable && (
                            <div
                              className={`treatment-add-button ${
                                selectedTreatments.includes(treatment.id)
                                  ? 'active'
                                  : ''
                              }`}
                              onClick={e => {
                                e.stopPropagation();
                                onToggleTreatment(treatment);
                              }}>
                              {selectedTreatments.includes(treatment.id) ? (
                                <Unicons.UilCheck size="16" />
                              ) : (
                                <Unicons.UilPlus size="16" />
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          )}
          {searchText && (
            <div className="treatment-list">
              {filteredTreatments.map(treatment => (
                <div
                  className={`treatment-item ${
                    treatment.id === selectedTreatmentId ? 'active' : ''
                  }`}
                  key={treatment.id}>
                  <div
                    className="treatment-item-title"
                    onClick={() => onSelectTreatment(treatment.id)}>
                    <div className="question-button">
                      <Unicons.UilQuestion size="16" />
                    </div>
                    <div className="treatment-item-title">
                      <span>{treatment.name}</span>
                      <span>-</span>
                      <span className="subtitle">in Biologic</span>
                    </div>
                    {editable && (
                      <div
                        className={`treatment-add-button ${
                          selectedTreatments.includes(treatment.id)
                            ? 'active'
                            : ''
                        }`}
                        onClick={e => {
                          e.stopPropagation();
                          onToggleTreatment(treatment);
                        }}>
                        {selectedTreatments.includes(treatment.id) ? (
                          <Unicons.UilCheck size="16" />
                        ) : (
                          <Unicons.UilPlus size="16" />
                        )}
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className={`treatment-details ${treatmentToShow ? 'open' : ''}`}>
        <div className="card">
          <div className="card-header">
            <h3>Treatment details</h3>
            <Unicons.UilTimes
              size="24"
              color="#000"
              onClick={onCloseTreatmentDetails}
              className="close-button"
            />
          </div>
          {selectedTreatmentId && (
            <div className="card-content treatment-info">
              <label>CATEGORY</label>
              <h4>{treatmentToShow.name}</h4>
              {treatmentToShow.brand_names && (
                <>
                  <hr />
                  <h4>Brand names</h4>
                  <p>{treatmentToShow.brand_names.join(', ')}</p>
                </>
              )}
              {treatmentToShow.generic && (
                <>
                  <hr />
                  <h4>Generic available</h4>
                </>
              )}
              <hr />
              {treatmentToShow?.metadata?.how && (
                <div className="info-item">
                  <h4>How does it works</h4>
                  <p>{treatmentToShow.metadata.how}</p>
                </div>
              )}
              {treatmentToShow?.metadata?.who && (
                <div className="info-item">
                  <h4>Who is this for?</h4>
                  <p>{treatmentToShow.metadata.who}</p>
                </div>
              )}
              {treatmentToShow?.metadata?.use && (
                <div className="info-item">
                  <h4>How do I use it?</h4>
                  <p>{treatmentToShow.metadata.use}</p>
                </div>
              )}
              {treatmentToShow?.metadata?.use && (
                <div className="info-item">
                  <h4>How well does it work?</h4>
                  <p>{treatmentToShow.metadata.efficacy}</p>
                </div>
              )}
              <div className="info-item">
                <div className="info-item-title">
                  <h4> How much does it cost?</h4>
                  <div className="help-button" data-tip={PRICING_SCALE_TOOLTIP}>
                    <HelperIcon />
                  </div>
                </div>
                <div className="price-badge">
                  {getCostLabel(treatmentToShow.metadata.cost)}
                </div>
              </div>
            </div>
          )}
          {!selectedTreatmentId && (
            <div className="card-content treatments-placeholder">
              <div className="placeholder-icon">
                <Topical fill="#fff" />
              </div>
              <h4>Select a treatment to view the details</h4>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(TreatmentsSelection);
