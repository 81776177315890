import React, { useRef } from 'react';
import MeetHero from '../../../assets/images/hcp-landing/hcp-hero.webp';
import { useFirstTimeVisible } from '../../../components/shared/hooks/useFirstTimeVisible';

const MeetEczemawise = ({ onSectionIsVisible }) => {
  const ref = useRef();
  const isVisible = useFirstTimeVisible(ref, onSectionIsVisible);

  return (
    <div className="meet-eczemawise" ref={ref}>
      <div className="meet-info">
        <h1>
          Meet EczemaWise, the app to <span>help</span> patients manage their
          eczema.
        </h1>
        <p>
          EczemaWise empowers patients to track their symptoms and treatments,
          leading to more informed clinical decisions and personalized treatment
          plans.
        </p>
      </div>
      <div className="meet-hero">
        <img src={MeetHero} alt="" />
      </div>
    </div>
  );
};

export default MeetEczemawise;
