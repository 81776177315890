import React, { useRef } from 'react';

import Feature1Icon from '../../../assets/images/hcp-landing/feature-1-trackers.svg';
import Feature2Icon from '../../../assets/images/hcp-landing/feature-2-images.svg';
import Feature3Icon from '../../../assets/images/hcp-landing/feature-3-treatments.svg';
import Feature4Icon from '../../../assets/images/hcp-landing/feature-4-triggers.svg';
import Feature5Icon from '../../../assets/images/hcp-landing/feature-5-trends.svg';
import Feature6Icon from '../../../assets/images/hcp-landing/feature-6-appointments.svg';
import Feature7Icon from '../../../assets/images/hcp-landing/feature-7-blog.svg';
import { useFirstTimeVisible } from '../../../components/shared/hooks/useFirstTimeVisible';

const EczemawiseFeatures = ({ onSectionIsVisible }) => {
  const ref = useRef();
  const isVisible = useFirstTimeVisible(ref, onSectionIsVisible);

  return (
    <div className="eczemawise-features">
      <h2>With EczemaWise, patients can:</h2>
      <div className="features">
        <div className="feature">
          <img src={Feature1Icon} alt="" />
          <p>Track symptoms using validated patient-reported outcomes (PROs)</p>
        </div>
        <div className="feature">
          <img src={Feature2Icon} alt="" />
          <p>Include photos with tracking entries</p>
        </div>
        <div className="feature">
          <img src={Feature3Icon} alt="" />
          <p>Record treatments taken and set treatment reminders</p>
        </div>
        <div className="feature">
          <img src={Feature4Icon} alt="" />
          <p>Monitor potential triggers</p>
        </div>
        <div className="feature" ref={ref}>
          <img src={Feature5Icon} alt="" />
          <p>Observe PRO trends over time</p>
        </div>
        <div className="feature">
          <img src={Feature6Icon} alt="" />
          <p>
            Prepare for upcoming appointments and create an appointment summary
            to share
          </p>
        </div>
        <div className="feature">
          <img src={Feature7Icon} alt="" />
          <p>
            Stay informed with articles from the National Eczema Association
            (NEA)
          </p>
        </div>
      </div>
    </div>
  );
};

export default EczemawiseFeatures;
