import React, { useEffect, useState } from 'react';
import * as Unicons from '@iconscout/react-unicons';

const GetReady = ({ upcomingAppointments }) => {
  const [active, setActive] = useState(true);

  useEffect(() => {
    if (upcomingAppointments > 0 && active) {
      setActive(false);
    } else if (upcomingAppointments === 0 && !active) {
      setActive(true);
    }
  }, [upcomingAppointments]);

  return (
    <div className={`card collapsible ${active ? 'active' : ''}`}>
      <div className="card-header" onClick={() => setActive(!active)}>
        <h3>Get ready for your appointment</h3>
        <div className="card-header-button">
          <Unicons.UilAngleDown size="24" />
        </div>
      </div>
      <div className="card-content">
        <p>
          Once you’ve created an appointment, complete five easy steps to
          prepare for your visit:
        </p>
        <ol>
          <li>Record topics to discuss</li>
          <li>Evaluate recent symptoms</li>
          <li>Assess treatment options</li>
          <li>Snap or select photos of your skin</li>
          <li>Save your information</li>
        </ol>
        <p>
          You’ll receive an appointment summary to show your doctor and help
          guide your discussion.
        </p>
      </div>
    </div>
  );
};

export default GetReady;
