import React, { useRef } from 'react';

import AppStoreButton from '../../../assets/images/hcp-landing/download-from-appstore.png';
import PlayStoreButton from '../../../assets/images/hcp-landing/download-from-playstore.png';
import MadeEasyBackground from '../../../assets/images/hcp-landing/made-asy-bg.svg';
import MadeEasyPhoneCenter from '../../../assets/images/hcp-landing/made-easy-phone-center.webp';
import MadeEasyPhoneLeft from '../../../assets/images/hcp-landing/made-easy-phone-left.webp';
import MadeEasyPhoneRight from '../../../assets/images/hcp-landing/made-easy-phone-right.webp';
import CommentIcon from '../../../assets/images/hcp-landing/comment-icon.svg';

import { useFirstTimeVisible } from '../../../components/shared/hooks/useFirstTimeVisible';
import MixpanelService, { MixpanelEvents } from '../../../libs/mixpanel';

const EczemaMadeEasy = ({ onSectionIsVisible }) => {
  const ref = useRef();
  const isVisible = useFirstTimeVisible(ref, onSectionIsVisible);

  const trackAppstoreClick = () => {
    MixpanelService.track(MixpanelEvents.DownloadAppStoreTappedHCP);
  };

  const trackPlaystoreClick = () => {
    MixpanelService.track(MixpanelEvents.DownloadGooglePlayTappedHCP);
  };

  return (
    <div className="made-easy">
      <div className="made-easy-info">
        <h2>
          Eczema Management Made <span>Easy</span>
        </h2>
        <p>
          Help your patients manage their disease between visits and prepare for
          more informed care conversations with you.
        </p>
        <p>
          EczemaWise is free and available for Android and iOS and as a web
          app. 
        </p>
        <div className="stores-links" ref={ref}>
          <a
            className="store-link"
            href="https://apps.apple.com/us/app/id1522743215"
            target="_blank"
            rel="noreferrer"
            onClick={trackAppstoreClick}>
            <img src={AppStoreButton} alt="" />
          </a>
          <a
            className="store-link"
            href="https://play.google.com/store/apps/details?id=com.eczemawiseapp"
            target="_blank"
            rel="noreferrer"
            onClick={trackPlaystoreClick}>
            <img src={PlayStoreButton} alt="" />
          </a>
        </div>
      </div>
      <div className="made-easy-hero">
        <img className="made-easy-bg" src={MadeEasyBackground} alt="" />
        <div className="phones">
          <img className="phone phone-left" src={MadeEasyPhoneLeft} alt="" />
          <img className="phone phone-right" src={MadeEasyPhoneRight} alt="" />
          <img className="phone" src={MadeEasyPhoneCenter} alt="" />
          <div className={`review ${isVisible ? 'show' : ''}`}>
            <div className="review-content">
              <img src={CommentIcon} alt="" />
              <blockquote>
                This app is just what I’ve needed for years! It’s so hard to
                remember what I ate or did that causes my eczema to flare.
              </blockquote>
            </div>
            <span className="review-user">– EczemaWise user</span>
          </div>
          <div className={`review review-right ${isVisible ? 'show' : ''}`}>
            <div className="review-content">
              <img src={CommentIcon} alt="" />
              <blockquote>
                EczemaWise helps patients understand and communicate a much
                broader story of their disease, which helps us tailor their
                treatment plan accordingly.
              </blockquote>
            </div>
            <span className="review-user">
              Dr. Lawrence Eichenfield, MD,
              <br /> FAAD, FAAP and Dr. Eric
              <br /> Simpson, MD, MCR
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EczemaMadeEasy;
