import React from 'react';
import { Link, NavLink } from 'react-router-dom';

// TRACKING
import MixpanelService, { MixpanelEvents } from '../libs/mixpanel';

// ICONS
import LogoNEA from '../assets/logos/EczemaLogoHorizontal.png';

export default function NavigationHCP() {
  const trackLoginTapped = () => {
    MixpanelService.track(MixpanelEvents.LogInTappedHCP);
  };

  const trackSignUpTapped = () => {
    MixpanelService.track(MixpanelEvents.SignUpTappedHCP);
  };

  return (
    <header className="header header-light">
      <div className="site-logo-wrapper">
        <Link
          to="/dashboard"
          onClick={() =>
            MixpanelService.track(MixpanelEvents.EczemaWiseLogoTappedHCP)
          }>
          <img src={LogoNEA} className={'logo-nea__horizontal'} alt="logo" />
        </Link>
      </div>

      <div className="desktop-nav-wrapper">
        <nav className="desktop-nav">
          {/* Temporarily removing login/signup buttons */}
          {/* <NavLink
            to="/for-healthcare-providers"
            className="button-border"
            onClick={trackLoginTapped}>
            login
          </NavLink>
          <NavLink
            to="/for-healthcare-providers"
            className="button-border button-solid"
            onClick={trackSignUpTapped}>
            sign up
          </NavLink> */}
        </nav>
      </div>
    </header>
  );
}
