import React, { useState, useEffect, memo } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

// COMPONENTS
import ProgressHeader from './components/ProgressHeader';
import Button from '../../components/shared/button';

// ICONS
import * as Unicons from '@iconscout/react-unicons';

// REDUX
import { useAppDispatch, useAppSelector } from '../../store';
import { appointmentPrepActions } from '../../store/appointmentPrep';
import { fetchAppointmentMetadata } from '../../store/appointmentPrep/appointmentPrep-thunks';
import { PrepStep1, PrepStep2, PrepStep3, PrepStep4, PrepSummary } from './';

// APOLLO
import { useMutation } from 'react-apollo';
import { addAppointmentMetadata } from '../../libs/graphql/mutations/user';

const AppointmentPrep = props => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { me } = props;

  // LOCAL STATE
  const [step, setStep] = useState(1);
  const [saving, setSaving] = useState(false);

  // REDUX STATE
  const appointmentPrep = useAppSelector(state => state.appointmentPrep);
  const metadata = useAppSelector(state => state.appointmentPrep.metadata);
  const complete = useAppSelector(
    state => state.appointmentPrep.metadata_added,
  );

  // APOLLO
  const [saveAppointmentMetadata] = useMutation(addAppointmentMetadata);

  useEffect(() => {
    setStep(1);
    scrollToHeader();
    dispatch(appointmentPrepActions.reset());
  }, []);

  useEffect(() => {
    setStep(1);
    dispatch(appointmentPrepActions.reset());
    dispatch(fetchAppointmentMetadata(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (complete) {
      setStep(5);
    } else {
      setStep(1);
    }
  }, [complete]);

  const getCurrentStepValidation = () => {
    if (step === 1) {
      return (
        appointmentPrep.issues?.length > 0 &&
        appointmentPrep.priorities?.length > 0
      );
    }
    return true;
  };

  const scrollToHeader = () => {
    const header = document.getElementById('progressHeader');
    setTimeout(() => {
      header.scrollIntoView({ behavior: 'smooth' });
    }, 100);
  };

  const goNextStep = () => {
    setStep(step + 1);
    scrollToHeader();
  };

  const goPrevStep = () => {
    scrollToHeader();
    setStep(step - 1);
  };

  const onCancel = () => {
    props.history.push('/provider-prep');
  };

  const saveAppointment = async () => {
    setSaving(true);

    const metadata = {
      appointment_id: id,
      issues: appointmentPrep.issues,
      priorities: appointmentPrep.priorities,
      focus_notes: '',
      picture_self: appointmentPrep.picture_self,
      picture_social: appointmentPrep.picture_social,
      picture_work: appointmentPrep.picture_work,
      treatments: appointmentPrep.selectedTreatmentsIds,
      images: appointmentPrep.selectedImagesIds,
      images_for_print: [],
      otherImages: [],
    };

    try {
      const response = await saveAppointmentMetadata({
        variables: {
          metadata,
        },
        errorPolicy: 'all',
      });

      if (response.data?.addAppointmentMetadata) {
        window.location = '/provider-prep';
      }
    } catch (error) {
      console.log(error);
    } finally {
      setSaving(false);
    }
  };

  const currentStepValid = getCurrentStepValidation();

  return (
    <div className="main-dashboard page-provider-prep">
      <div className="provider-prep-container">
        <header>
          <div className="date-bar">
            <div className="main-dashboard__sidebar">
              <div className="sidebar-title">
                <Unicons.UilTimes size="24" color="#000" onClick={onCancel} />
                <h1 className="headers headers__black100 headers__uppercase headers__mediumbold">
                  Create new appointment
                </h1>
              </div>
            </div>
            <div className="header-buttons">
              <button
                className="button button__secondary100-inverted button__btn-14-600 button-rectangle-medium buttonCancel"
                onClick={onCancel}>
                Cancel
              </button>
            </div>
          </div>
        </header>
        <ProgressHeader step={step} />
        <div className="manage-container">
          <div className="manage-content">
            {step === 0 && <div className="manage-content-loading">...</div>}
            {step === 1 && <PrepStep1 />}
            {step === 2 && <PrepStep2 />}
            {step === 3 && <PrepStep3 />}
            {step === 4 && <PrepStep4 me={me} />}
            {step === 5 && <PrepSummary me={me} />}
          </div>
        </div>
        {step > 0 && (
          <div className="footer-buttons-bar">
            <div className="buttons-container">
              {metadata ? (
                <>
                  <Button
                    onClick={onCancel}
                    className="button button__secondary100 button__btn-14-600 button-rectangle-skin-footer"
                    text={'Back'}
                  />
                </>
              ) : (
                <>
                  <Button
                    className="button button__secondary100-inverted button__btn-14-600 button-rectangle-skin-footer"
                    onClick={step === 1 ? onCancel : goPrevStep}
                    text={step === 1 ? 'Cancel' : 'Back'}
                    disabled={false}
                  />
                  {step < 5 ? (
                    <Button
                      onClick={goNextStep}
                      className="button button__secondary100 button__btn-14-600 button-rectangle-skin-footer"
                      text="Next"
                      disabled={!currentStepValid}
                    />
                  ) : (
                    <Button
                      onClick={saveAppointment}
                      className="button button__secondary100 button__btn-14-600 button-rectangle-skin-footer"
                      text={saving ? 'Saving...' : 'Save'}
                      disabled={saving}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(AppointmentPrep);
