import React, { useRef } from 'react';
import AccessDataHero from '../../../assets/images/hcp-landing/access-data-hero.svg';
import { useFirstTimeVisible } from '../../../components/shared/hooks/useFirstTimeVisible';

const AccessData = ({ onSectionIsVisible }) => {
  const ref = useRef();
  const isVisible = useFirstTimeVisible(ref, onSectionIsVisible);

  return (
    <div className="download-guide access-data">
      <div className="download-guide-hero">
        <img src={AccessDataHero} alt="" />
      </div>
      <div className="download-guide-info">
        <h2>
          Can I directly <span>access</span> my patient’s EczemaWise data?
        </h2>
        <p ref={ref}>
          No, there is no direct access for anyone but the account holder. It’s
          essential to discuss with your patient what information they should
          share with you (e.g., appointment summaries, treatments, photos), how
          often, and the preferred method (e.g., secure portal, printout).
        </p>
      </div>
    </div>
  );
};

export default AccessData;
