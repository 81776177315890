import { createSlice } from '@reduxjs/toolkit';
import { fetchAppointmentMetadata } from './appointmentPrep-thunks';
import moment from 'moment-timezone';

const initialState = {
  appointment: {},
  metadata: null,
  issues: [],
  priorities: [],
  picture_self: 0,
  picture_social: 0,
  picture_work: 0,
  selectedTreatmentsIds: [],
  selectedTreatmentsNames: [],
  selectedImagesIds: [],
  selectedImages: [],
  trends_end_date: moment().format('YYYY-MM-DD'),
  loading: false,
  error: null,
  metadata_added: false,
};

const appointmentPrepSlice = createSlice({
  name: 'appointmentPrep',
  initialState,
  reducers: {
    setAppointment(state, action) {
      state.appointment = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setIssues(state, action) {
      state.issues = action.payload;
    },
    setPriorities(state, action) {
      state.priorities = action.payload;
    },
    setValidStep1(state, action) {
      state.validStep1 = action.payload;
    },
    setPictureSelf(state, action) {
      state.picture_self = action.payload;
    },
    setPictureSocial(state, action) {
      state.picture_social = action.payload;
    },
    setPictureWork(state, action) {
      state.picture_work = action.payload;
    },
    toggleTreatment(state, action) {
      const treatment = action.payload;
      const treatmentId = treatment.id;
      const treatmentName = treatment.name;
      const newSelectedTreatmentsIds = [...state.selectedTreatmentsIds];
      const newSelectedTreatmentsNames = [...state.selectedTreatmentsNames];

      if (newSelectedTreatmentsIds.includes(treatmentId)) {
        newSelectedTreatmentsIds.splice(
          newSelectedTreatmentsIds.indexOf(treatmentId),
          1,
        );
        newSelectedTreatmentsNames.splice(
          newSelectedTreatmentsNames.indexOf(treatmentName),
          1,
        );
      } else {
        newSelectedTreatmentsIds.push(treatmentId);
        newSelectedTreatmentsNames.push(treatmentName);
      }

      state.selectedTreatmentsIds = newSelectedTreatmentsIds;
      state.selectedTreatmentsNames = newSelectedTreatmentsNames;
    },
    toggleImage(state, action) {
      const image = action.payload;
      const imageId = image.imageid;
      const newSelectedImagesIds = [...state.selectedImagesIds];
      const newImages = [...state.selectedImages];

      if (newSelectedImagesIds.includes(imageId)) {
        newSelectedImagesIds.splice(newSelectedImagesIds.indexOf(imageId), 1);
        newImages.splice(
          newImages.findIndex(img => img.imageid === imageId),
          1,
        );
      } else {
        newSelectedImagesIds.push(imageId);
        newImages.push(image);
      }

      state.selectedImagesIds = newSelectedImagesIds;
      state.selectedImages = newImages;
    },
    resetImages(state, action) {
      state.selectedImagesIds = [];
    },
    reset(state) {
      state.appointment = {};
      state.metadata = null;
      state.issues = [];
      state.priorities = [];
      state.picture_self = 0;
      state.picture_social = 0;
      state.picture_work = 0;
      state.selectedTreatmentsIds = [];
      state.selectedTreatmentsNames = [];
      state.selectedImagesIds = [];
      state.selectedImages = [];
      state.trends_end_date = moment().format('YYYY-MM-DD');
      state.loading = false;
      state.error = null;
      state.metadata_added = false;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchAppointmentMetadata.pending, state => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchAppointmentMetadata.fulfilled, (state, action) => {
      const { appointment, metadata } = action.payload;

      state.appointment = appointment;
      state.loading = false;

      if (appointment?.metadata_added) {
        state.metadata = metadata;
        state.issues = metadata.issues || [];
        state.priorities = metadata.priorities || [];
        state.picture_self = metadata.picture_self || 0;
        state.picture_social = metadata.picture_social || 0;
        state.picture_work = metadata.picture_work || 0;
        state.selectedTreatmentsIds = metadata.treatments || [];
        state.selectedTreatmentsNames = metadata.treatments_names || [];
        state.trends_end_date = metadata.trends_end_date;
        state.metadata_added = true;

        const selectedImages = [];
        for (const trackerDate of metadata.pictures) {
          for (const affectedArea of trackerDate.pictures) {
            for (const image of affectedArea.images) {
              selectedImages.push({
                imageid: image.id,
                area_affected: affectedArea.area_affected,
                tracker_date: trackerDate.tracker_date,
                favorite: false,
              });
            }
          }
        }
        state.selectedImages = selectedImages;
      }
    });
    builder.addCase(fetchAppointmentMetadata.rejected, (state, action) => {
      state.error = action.error.message;
      state.loading = false;
    });
  },
});

export const appointmentPrepActions = appointmentPrepSlice.actions;

export default appointmentPrepSlice.reducer;
