import gql from 'graphql-tag';
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import Helmet from 'react-helmet';
import { Redirect, Route, Switch, withRouter } from 'react-router';
import { version } from '../package.json';
import Footer from './components/Footer';
import Navigation from './components/Navigation';
import NavigationHCP from './components/NavigationHCP';
import deviceDetect from './deviceDetect';
// Queries
import { Me } from './libs/graphql/queries/me';
// Import Components 🔌
import ProtectedRoute from './libs/ProtectedRoute';
import routes from './routes'; // Import Routes 🗺
import './styles/base.scss'; // Import Styles 💃
import './styles/normalize.scss'; // Import Normalize 👩🏻‍🍳
import MixpanelService, { MixpanelEvents } from './libs/mixpanel';
import { ToastContainer } from 'react-toastify';

import { profileActiveTreatmentCategories } from './libs/treatmentOptionsUtils';

const HCP_ROUTE = 'for-healthcare-providers';

// The Main Container Component
const Main = ({ history }) => {
  const device = deviceDetect();
  const [time, setTime] = useState(new Date().toISOString());
  const [mixpanelTrackedLogin, setMixpanelTrackedLogin] = useState(false);
  const route = history.location.pathname;
  const isHCP = route.includes(HCP_ROUTE);

  const { loading, data } = useQuery(Me);
  const { data: surveyData, refetch } = useQuery(
    gql`
      query userSurveys($userid: ID) {
        userSurveys(userid: $userid) {
          id
          name
          uri
          name
          description
          time_to_complete
          started
          completed
          available_on
          end_date
        }
      }
    `,
    {
      variables: {
        userid: data && data.me && data.me.id,
      },
      skip: !data || !data.me || !data.me.id,
      fetchPolicy: 'no-cache',
    },
  );
  const [setDevice, {}] = useMutation(
    gql`
      mutation setDevice(
        $userid: ID!
        $os: String!
        $buildNumber: String!
        $lastUpdated: String!
        $appVersion: String!
        $model: String!
      ) {
        setDevice(
          userid: $userid
          os: $os
          buildNumber: $buildNumber
          lastUpdated: $lastUpdated
          appVersion: $appVersion
          model: $model
        ) {
          userid
          os
          buildNumber
          lastUpdated
          appVersion
          model
        }
      }
    `,
  );

  const me = data?.me || null;
  const userSurveys = (surveyData && surveyData.userSurveys) || [];

  useEffect(() => {
    MixpanelService.configure();
  }, []);

  useEffect(() => {
    MixpanelService.track('AppOpen', {
      FirstAppOpen: localStorage.getItem('AppOpen') !== 'true',
    });
    localStorage.setItem('AppOpen', 'true');
  }, []);

  // Setup Mixpanel to ensure everything is configured correctly for all users
  useEffect(() => {
    if (!loading && data?.me && data?.me?.id) {
      setupdMixpanelConfigs(data.me);
    }
  }, [loading, data]);

  const setupdMixpanelConfigs = me => {
    const { id, profile, admin } = me;
    const treatmentCategories = profileActiveTreatmentCategories(
      profile?.userTreatments || [],
    );
    const superProps = {
      ...treatmentCategories,
      UserId: id,
      ZipCode: profile?.zip,
      BirthYear: profile?.birth_year,
      EczemaRelation: profile?.eczema_relation,
      EczemaSentiment: profile?.feelings,
      NEAFamiliar: profile?.nea_familiarity,
      ProfileID: profile?.id,
    };

    // Identify user to ensure we will create the relationship between differents ids
    MixpanelService.identify(id);

    if (
      !mixpanelTrackedLogin &&
      profile?.id &&
      history.location?.state?.from === 'login'
    ) {
      MixpanelService.track(MixpanelEvents.UserLogin, {
        UserAdmin: admin,
      });
      MixpanelService.setProfileProperty('UserAdmin', admin);
      MixpanelService.setProfileProperty('ProfileID', profile?.id);
      MixpanelService.setProfileProperty('ZipCode', profile?.zip);
      setMixpanelTrackedLogin(true);
    }

    // Register Mixpanel Super Properties to ensure they are always tracked
    if (profile?.id && history.location.pathname === '/dashboard')
      MixpanelService.registerSuperProps(superProps);
  };

  if (loading) return 'Loading';

  if (me?.id && time) {
    setDevice({
      variables: {
        userid: me?.id,
        os: 'Web',
        buildNumber: device.client.name,
        lastUpdated: time,
        model: device.client.version,
        appVersion: version,
      },
    });
    setTime(null);
  }

  return (
    <div className="container">
      <Helmet
        title={'EczemaWise | Eczema Shared Decision Making'}
        meta={[
          {
            name: 'apple-mobile-web-app-title',
            content: 'EczemaWise',
          },
          {
            name: 'application-name',
            content: 'EczemaWise',
          },
          {
            name: 'viewport',
            content:
              'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0',
          },
        ]}></Helmet>
      {isHCP ? (
        <NavigationHCP />
      ) : (
        <Navigation id={(me && me.id) || undefined} userSurveys={userSurveys} />
      )}
      <div className="main">
        <Switch>
          <Route
            key="refresh-dashboard"
            path="/refresh-dashboard"
            exact
            render={() => {
              return <Redirect to="/dashboard" />;
            }}
          />
          {routes.map(route => {
            const C = route.component;
            if (route.auth) {
              return (
                <ProtectedRoute
                  key={route.name}
                  exact={route.exact}
                  path={route.path}
                  me={me}
                  render={() => {
                    return (
                      <C
                        me={me}
                        refetch={refetch}
                        userSurveys={userSurveys}
                        loading={loading}
                      />
                    );
                  }}
                />
              );
            }
            return (
              <Route
                key={route.name}
                exact={route.exact}
                path={route.path}
                render={() => {
                  return <C me={me} loading={loading} />;
                }}
              />
            );
          })}
        </Switch>
      </div>
      <Switch>
        <Route path="/surveys/:id" exact component={null} />
        <Route path="*" component={Footer} />
      </Switch>
      <ToastContainer />
    </div>
  );
};

export default withRouter(Main);
