import React, { useState, useRef, memo } from 'react';

const ProgressHeader = ({ step }) => {
  const [sticky, setSticky] = useState({ isSticky: false, offset: 0 });
  const headerRef = useRef(null);

  const handleScroll = (elTopOffset, elHeight) => {
    if (window.pageYOffset > 110) {
      setSticky({ isSticky: true, offset: elHeight });
    } else {
      setSticky({ isSticky: false, offset: 0 });
    }
  };

  return (
    <div
      id="progressHeader"
      className={`progressHeader ${sticky.isSticky ? ' sticky' : ''}`}
      ref={headerRef}>
      <div className="progressContainer">
        <div className="progressTitle">
          {step === 1 && 'STEP 1. Find Focus'}
          {step === 2 && 'STEP 2. Think Big Picture'}
          {step === 3 && 'STEP 3. Treatments'}
          {step === 4 && 'STEP 4. Photos'}
          {step === 5 && 'STEP 5. Appointment Summary'}
        </div>
        <div className="progressBars">
          <div className="progressBar active" />
          <div
            className={['progressBar', step > 1 ? 'active' : ''].join(' ')}
          />
          <div
            className={['progressBar', step > 2 ? 'active' : ''].join(' ')}
          />
          <div
            className={['progressBar', step > 3 ? 'active' : ''].join(' ')}
          />
          <div
            className={['progressBar', step > 4 ? 'active' : ''].join(' ')}
          />
        </div>
      </div>
    </div>
  );
};

export default memo(ProgressHeader);
