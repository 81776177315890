import gql from 'graphql-tag';

export const User = gql`
  query user($id: ID) {
    user(id: $id) {
      id
      name
      admin
      profileFlag
      notificationSettings {
        tracker_notifications
        survey_notifications
        medication_notifications
      }
      settings {
        id
        trackers {
          name
        }
      }
    }
  }
`;

export const UserAppointments = gql`
  query getAppointmentsByUserId($userid: ID!) {
    getAppointmentsByUserId(userid: $userid) {
      id
      date
      time
      notes
      provider_id
      provider_address_id
      provider_first_name
      provider_last_name
      provider_phone
      provider_address_1
      provider_address_2
      provider_city
      provider_state
      provider_zip
      metadata_added
    }
  }
`;

export const UserAppointmentsMetadata = gql`
  query getAppointmentMetadata($appointmentId: ID!) {
    getAppointmentMetadata(appointmentId: $appointmentId) {
      appointment {
        date
        time
        provider_first_name
        provider_last_name
        metadata_added
      }
      metadata {
        appointment_id
        issues
        issues_notes
        priorities
        focus_notes
        picture_self
        picture_social
        picture_work
        treatments
        treatments_names
        trends_end_date
        pdf_download
        chart_img
        pictures {
          tracker_date
          pictures {
            area_affected
            images {
              id
              userid
              filename
              mimetype
              encoding
              uri
            }
          }
        }
      }
    }
  }
`;

export const UserPoScorad = gql`
  query getMostRecentPoScoradDataByUserID($userid: ID!) {
    getMostRecentPoScoradDataByUserID(userid: $userid) {
      level
      tracker_date
    }
  }
`;

export const UserPoScoradHistory = gql`
  query getPoScoradHistory($payload: PoScoradHistoryPayload!) {
    getPoScoradHistory(payload: $payload) {
      History {
        level
        tracker_date
      }
      averageLevel
    }
  }
`;

export const UserProviders = gql`
  query GetProviders($providerId: ID) {
    getProviders(providerId: $providerId) {
      id
      first_name
      last_name
      addresses {
        id
        address_default
        address_1
        address_2
        phone
        city
        state
        zip
      }
    }
  }
`;

export const GetProviders = gql`
  query GetProviders($providerId: ID) {
    getProviders(providerId: $providerId) {
      id
      first_name
      last_name
      addresses {
        id
        address_default
        address_1
        address_2
        phone
        city
        state
        zip
      }
    }
  }
`;
