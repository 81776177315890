import React from 'react';
import { createRoot } from 'react-dom/client';

import { BrowserRouter } from 'react-router-dom';
import Client from './apollo/client';
import Provider from './apollo/provider';
import Main from './Main';

// REDUX
import { Provider as ReduxProvider } from 'react-redux';
import store from './store';

const root = createRoot(document.getElementById('root'));

root.render(
  <Provider client={Client}>
    <ReduxProvider store={store}>
      <BrowserRouter>
        <Main />
      </BrowserRouter>
    </ReduxProvider>
  </Provider>,
);
