import gql from 'graphql-tag';

export const createHCProviderSF = gql`
  mutation createHCProviderSF(
    $HCProvider: HCProviderInput!
  ) {
    createHCProviderSF(
        HCProvider: $HCProvider
    ) {
        email
        was_new_user
    }
  }
`;