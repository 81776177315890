import React, { useEffect, useState } from 'react';

// LIBS
import moment from 'moment-timezone';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import * as Unicons from '@iconscout/react-unicons';

// COMPONENTS
import ProviderOption, { ProviderAddButton } from '../forms/ProviderOption';
import Input from '../shared/forms/input';
import TextArea from '../shared/forms/textarea';
import ErrorAlert from '../shared/ErrorAlert';
import Button from '../shared/button';

// APOLLO
import { useMutation, useQuery } from 'react-apollo';
import { GetProviders } from '../../libs/graphql/queries/user';
import {
  createUserAppointment,
  createProvider,
  createProviderAddress,
  updateProvider,
  updateProviderAddress,
  deleteProvider,
  deleteProviderAddress,
  deleteAppointment,
} from '../../libs/graphql/mutations/user';

// UTILS
import config from '../../config';
import MixpanelService, {
  MixpanelEvents,
  MIXPANEL_DAY_FORMAT,
  MIXPANEL_TIME_FORMAT,
} from '../../libs/mixpanel';
import analytics from '../../libs/analytics';

const MAX_INPUT_LENGTH = 64;
const MAX_NOTES_LENGTH = 500;
const ROUND_MINUTES = 15;

const roundToNextHour = () => {
  const roundedUp =
    Math.ceil(moment().minute() / ROUND_MINUTES) * ROUND_MINUTES;
  return moment()
    .minute(roundedUp || ROUND_MINUTES)
    .add(1, 'hour')
    .second(0);
};

const AppointmentForm = props => {
  const { appointmentToEdit, userid } = props;
  const { onCancel, onAddAppointment, onDeleteAppointment } = props;

  // STATES
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [formData, setFormData] = useState({
    appointmentId: appointmentToEdit?.id || '',
    userid: userid,
    date: appointmentToEdit?.date || moment().format(),
    time: appointmentToEdit?.time || roundToNextHour().format(),
    provider_first_name: appointmentToEdit?.provider_first_name || '',
    provider_last_name: appointmentToEdit?.provider_last_name || '',
    provider_address_1: appointmentToEdit?.provider_address_1 || '',
    provider_address_2: appointmentToEdit?.provider_address_2 || '',
    provider_city: appointmentToEdit?.provider_city || '',
    provider_state: appointmentToEdit?.provider_state || '',
    provider_zip: appointmentToEdit?.provider_zip || '',
    provider_phone: appointmentToEdit?.provider_phone || '',
    notes: appointmentToEdit?.notes || '',
    address_default: true,
  });
  const [invalid, setInvalid] = useState({});
  const [saveProvider, setSaveProvider] = useState(true);
  const [saveProviderAddress, setSaveProviderAddress] = useState(true);
  const [selectedProviderId, setSelectedProviderId] = useState(
    appointmentToEdit?.provider_id || null,
  );
  const [selectedAddressId, setSelectedAddressId] = useState(
    appointmentToEdit?.provider_address_id || null,
  );
  const [providerEditMode, setProviderEditMode] = useState('new');
  const [addressEditMode, setAddressEditMode] = useState('new');
  const [providerEditId, setProviderEditId] = useState(null);
  const [addressEditId, setAddressEditId] = useState(null);
  const [showAddress, setShowAddress] = useState(false);
  const [showNotes, setShowNotes] = useState(false);
  const [saving, setSaving] = useState(false);

  // APOLLO
  const [createUserAppointmentMutation] = useMutation(createUserAppointment);
  const [createProviderMutation] = useMutation(createProvider);
  const [createProviderAddressMutation] = useMutation(createProviderAddress);
  const [updateProviderAddressMutation] = useMutation(updateProviderAddress);
  const [deleteProviderAddressMutation] = useMutation(deleteProviderAddress);
  const [updateProviderMutation] = useMutation(updateProvider);
  const [deleteProviderMutation] = useMutation(deleteProvider);
  const [deleteAppointmentMutation] = useMutation(deleteAppointment);
  const { data: providersData, refetch: refetchProviders } = useQuery(
    GetProviders,
    {
      cache: false,
    },
  );

  // SELECTED PROVIDER / ADDRESS
  const providers = providersData?.getProviders || [];
  const selectedProvider =
    providers.find(provider => provider.id === selectedProviderId) || null;
  const selectedAddress =
    selectedProvider?.addresses.find(
      address => address.id === selectedAddressId,
    ) || null;

  const handleDateChange = date => {
    let value = moment(date).format();
    setFormData({
      ...formData,
      date: value,
    });
  };

  const handleTimeChange = time => {
    let value = moment(time).format();
    setFormData({
      ...formData,
      time: value,
    });
  };

  const handleInputChange = (name, e) => {
    let value = e.target ? e.target.value : e.value;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const resetForm = () => {
    setDeleteAlert(false);
    setErrorMessage('');
    setFormData({
      ...formData,
      appointmentId: '',
      provider_first_name: '',
      provider_last_name: '',
      provider_address_1: '',
      provider_address_2: '',
      provider_city: '',
      provider_state: '',
      provider_zip: '',
      provider_phone: '',
      notes: '',
      address_default: false,
    });
    setInvalid({});
  };

  const getProviderPayload = () => {
    if (!formData.provider_first_name || !formData.provider_last_name) {
      return null;
    }
    return {
      first_name: formData.provider_first_name,
      last_name: formData.provider_last_name,
    };
  };

  const getAddressPayload = () => {
    if (!formData.provider_address_1) {
      return null;
    }
    return {
      address_1: formData.provider_address_1,
      address_2: formData.provider_address_2,
      city: formData.provider_city,
      state: formData.provider_state,
      zip: formData.provider_zip,
      phone: formData.provider_phone,
      address_default: formData.address_default,
    };
  };

  const storeProvider = async () => {
    const providerData = getProviderPayload();
    const addressData = getAddressPayload();

    if (!providerData) {
      return null;
    }

    const payload = {
      ...providerData,
      addresses: addressData
        ? [
            {
              ...addressData,
              address_default: true,
            },
          ]
        : [],
    };

    const newProvider = await createProviderMutation({
      variables: {
        provider: payload,
      },
      errorPolicy: 'all',
    });

    if (newProvider.data?.createProvider) {
      MixpanelService.setProfileProperty('AddedContact', providers.length + 1);
    }

    return newProvider.data?.createProvider || null;
  };

  const storeAddress = async () => {
    const addressData = getAddressPayload();
    if (!addressData) {
      return null;
    }

    const payload = {
      providerId: selectedProviderId,
      address: {
        ...addressData,
        address_default: formData.address_default,
      },
    };

    const newAddress = await createProviderAddressMutation({
      variables: payload,
      errorPolicy: 'all',
    });

    return newAddress.data?.createProviderAddress || null;
  };

  const updateAddress = async () => {
    const addressData = getAddressPayload();
    const payload = {
      providerId: selectedProviderId,
      addressId: addressEditId,
      address: {
        ...addressData,
      },
    };

    const newAddress = await updateProviderAddressMutation({
      variables: payload,
      errorPolicy: 'all',
    });

    if (payload.address.address_default) {
      setSelectedAddressId(addressEditId);
    }

    return newAddress.data?.updateProviderAddress || null;
  };

  const updateExistingProvider = async () => {
    const providerData = getProviderPayload();
    const payload = {
      providerId: providerEditId,
      provider: providerData,
    };

    const updatedProvider = await updateProviderMutation({
      variables: payload,
      errorPolicy: 'all',
    });

    return updatedProvider.data?.updateProvider || null;
  };

  const deleteAddress = async () => {
    const deletedAddress = await deleteProviderAddressMutation({
      variables: {
        addressId: addressEditId,
      },
      errorPolicy: 'all',
    });

    return deletedAddress.data?.deleteProviderAddress || null;
  };

  const deleteExistingProvider = async () => {
    const deletedProvider = await deleteProviderMutation({
      variables: {
        providerId: providerEditId,
      },
      errorPolicy: 'all',
    });

    if (deletedProvider.data?.deleteProvider) {
      MixpanelService.setProfileProperty('AddedContact', providers.length - 1);
    }

    return deletedProvider.data?.deleteProvider || null;
  };

  const validateFields = data => {
    const today = moment();
    const date = moment(data.date, 'YYYY-MM-DDTHH:mm:ss[Z]');
    const time = moment(data.time, 'YYYY-MM-DDTHH:mm:ss[Z]');

    if (date.isBefore(today, 'day')) {
      setInvalid({
        date: 'Date cannot be in the past',
      });
      return false;
    }

    if (date.isSame(today, 'day') && time.isBefore(today, 'minute')) {
      setInvalid({
        time: 'Time cannot be in the past',
      });
      return false;
    }

    if (!data.provider_first_name) {
      setInvalid({
        provider_first_name: 'Enter a provider first name',
      });
      return false;
    }

    if (!data.provider_last_name) {
      setInvalid({
        provider_last_name: 'Enter a provider last name',
      });
      return false;
    }

    return true;
  };

  const createAppointment = async () => {
    setErrorMessage('');
    let provider_id = null;
    let provider_address_id = null;

    const data = {
      ...formData,
    };

    if (providerEditMode === 'new' && saveProvider) {
      const newProvider = await storeProvider();
      if (newProvider) {
        provider_id = newProvider.id;
        provider_address_id = newProvider.addresses[0]?.id || null;
      }
    } else if (
      providerEditMode === '' &&
      addressEditMode === 'new' &&
      saveProviderAddress
    ) {
      const newAddress = await storeAddress();
      if (newAddress) {
        provider_address_id = newAddress.id;
      }
    }

    if (selectedProvider) {
      provider_id = selectedProvider.id;
      data.provider_first_name = selectedProvider.first_name;
      data.provider_last_name = selectedProvider.last_name;
    }

    if (selectedAddress) {
      provider_address_id = selectedAddress.id;
      data.provider_address_1 = selectedAddress.address_1;
      data.provider_address_2 = selectedAddress.address_2;
      data.provider_city = selectedAddress.city;
      data.provider_state = selectedAddress.state;
      data.provider_zip = selectedAddress.zip;
      data.provider_phone = selectedAddress.phone;
    }

    if (!validateFields(data)) {
      return;
    }

    const appointmentData = {
      ...data,
      appointmentId: appointmentToEdit?.id || null,
      date: data.date,
      time: data.time,
      provider_id,
      provider_address_id,
    };

    try {
      const newAppointment = await createUserAppointmentMutation({
        variables: appointmentData,
        errorPolicy: 'all',
      });

      if (!newAppointment.data?.createAppointment) {
        throw new Error('Error creating appointment');
      }

      trackAppointmentEvents(appointmentData);
      onAddAppointment();
    } catch (error) {
      setErrorMessage('Error creating appointment. Please try again.');
    }
  };

  const trackAppointmentEvents = appointmentData => {
    const mixpanelEvent = appointmentToEdit?.id
      ? MixpanelEvents.AppointmentEdited
      : MixpanelEvents.AppointmentCreated;

    MixpanelService.track(mixpanelEvent, {
      AppointmentDate: moment
        .utc(appointmentData.date)
        .format(MIXPANEL_DAY_FORMAT),
      AppointmentTime: moment
        .utc(appointmentData.time)
        .format(MIXPANEL_TIME_FORMAT),
      ProviderFirstName: appointmentData.provider_first_name,
      ProviderLastName: appointmentData.provider_last_name,
      ProviderPhoneNumber: appointmentData.provider_phone,
      ProviderStreetAddress1: appointmentData.provider_address_1,
      ProviderStreetAddress2: appointmentData.provider_address_2,
      ProviderCity: appointmentData.provider_city,
      ProviderState: appointmentData.provider_state,
      ProviderZip: appointmentData.provider_zip,
      AppointmentNotes: appointmentData.notes,
      DateAppointmentCreated: moment
        .utc()
        .format(`${MIXPANEL_DAY_FORMAT}T${MIXPANEL_TIME_FORMAT}`),
      AppointmentID: appointmentToEdit?.id || null,
    });

    analytics().logEvent('manage_finish_appointment', {});
  };

  const findStateOption = state => {
    return config.states.find(option => {
      return option.value === state;
    });
  };

  const selectProvider = providerId => {
    if (providerId === selectedProviderId) {
      return;
    }
    const provider = providers.find(provider => provider.id === providerId);
    if (!provider) {
      return;
    }
    setSelectedProviderId(provider.id);
  };

  const selectAddress = addressId => {
    setSelectedAddressId(addressId);
  };

  const pickDefaultProvider = () => {
    const defaultProviderId = providers[0]?.id || null;
    const defaultAddressId =
      providers[0]?.addresses.find(address => address.address_default)?.id ||
      providers[0]?.addresses[0]?.id ||
      null;

    setSelectedProviderId(defaultProviderId);
    if (defaultProviderId) {
      setProviderEditMode('');
    } else {
      setProviderEditMode('new');
    }

    setSelectedAddressId(defaultAddressId);
    if (defaultAddressId) {
      setAddressEditMode('');
      setShowAddress(true);
    } else {
      setAddressEditMode('new');
    }
  };

  const pickDefaultAddress = () => {
    const selectedProvider = providers.find(
      provider => provider.id === selectedProviderId,
    );
    const defaultAddressId =
      selectedProvider?.addresses.find(address => address.address_default)
        ?.id ||
      selectedProvider?.addresses[0]?.id ||
      null;

    setAddressEditMode(defaultAddressId ? '' : 'new');
    setSelectedAddressId(defaultAddressId);
  };

  const resetDefaultProvider = () => {
    setSelectedProviderId(null);
    setSelectedAddressId(null);
    setProviderEditMode('new');
    setAddressEditMode('new');
  };

  useEffect(() => {
    if (!providers.length) {
      resetDefaultProvider();
    } else if (!selectedProviderId) {
      pickDefaultProvider();
    } else if (!selectedAddressId) {
      pickDefaultAddress();
    }
  }, [providers]);

  useEffect(() => {
    pickDefaultAddress();
  }, [selectedProviderId]);

  useEffect(() => {
    refetchProviders();
  }, []);

  const onAddProviderPress = () => {
    setProviderEditMode('add');
    setAddressEditMode('add');
    setTimeout(() => {
      document.getElementById('provider_first_name').focus();
    }, 100);
  };

  const onCancelAddProviderPress = () => {
    resetForm();
    setProviderEditMode('');
    setAddressEditMode(selectedAddressId ? '' : 'new');
  };

  const onAddAddressPress = () => {
    resetForm();
    setAddressEditMode('add-address');
    setTimeout(() => {
      document.getElementById('address_1').focus();
    }, 200);
  };

  const onEditAddressPress = address => {
    setAddressEditId(address.id);
    setFormData({
      ...formData,
      provider_address_1: address.address_1,
      provider_address_2: address.address_2,
      provider_city: address.city,
      provider_state: address.state,
      provider_zip: address.zip,
      provider_phone: address.phone,
      address_default: address.address_default,
    });
    setAddressEditMode('edit');
  };

  const setSaveDefaultAddress = checked => {
    setFormData({
      ...formData,
      address_default: checked,
    });
  };

  const onEditProviderPress = provider => {
    setProviderEditId(provider.id);
    setFormData({
      ...formData,
      provider_first_name: provider.first_name,
      provider_last_name: provider.last_name,
    });
    setProviderEditMode('edit');
  };

  const onRemoveProviderPress = async () => {
    const confirmed = window.confirm(
      'Are you sure you want to remove this provider?',
    );
    if (confirmed) {
      const deletedProvider = await deleteExistingProvider();
      if (deletedProvider) {
        if (providerEditId === selectedProviderId) {
          setSelectedProviderId(null);
        }
        onCancelAddProviderPress();
        refetchProviders();
      }
    }
  };

  const onCancelAddAddressPress = () => {
    resetForm();
    setAddressEditMode('');
  };

  const onSaveNewProvider = async () => {
    const newProvider = await storeProvider();
    if (newProvider) {
      setSelectedProviderId(newProvider.id);
      setSelectedAddressId(newProvider.addresses[0]?.id || null);
      onCancelAddProviderPress();
      setTimeout(() => {
        refetchProviders();
      }, 100);
    }
  };

  const onUpdateProvider = async () => {
    const updatedProvider = await updateExistingProvider();
    if (updatedProvider) {
      onCancelAddProviderPress();
      refetchProviders();
    }
  };

  const onSaveNewAddress = async () => {
    const newAddress = await storeAddress();
    if (newAddress) {
      setSelectedAddressId(newAddress.id);
      onCancelAddAddressPress();
      refetchProviders();
    }
  };

  const onUpdateAddress = async () => {
    const updatedAddress = await updateAddress();
    if (updatedAddress) {
      onCancelAddAddressPress();
      refetchProviders();
    }
  };

  const onRemoveAddressPress = async () => {
    const confirmed = window.confirm(
      'Are you sure you want to remove this address?',
    );
    if (confirmed) {
      const deletedAddress = await deleteAddress();
      if (deletedAddress) {
        if (addressEditId === selectedAddressId) {
          setSelectedAddressId(null);
        }
        onCancelAddAddressPress();
        refetchProviders();
      }
    }
  };

  const deleteExistingAppointment = async () => {
    try {
      const deletedAppointment = await deleteAppointmentMutation({
        variables: {
          appointmentId: appointmentToEdit?.id,
        },
        errorPolicy: 'all',
      });
      if (deletedAppointment.data?.deleteAppointment) {
        return true;
      }
    } catch (error) {}
    return false;
  };

  const onDeletAppointmentPress = async () => {
    if (window.confirm('Are you sure you want to delete this appointment?')) {
      const deleted = await deleteExistingAppointment();
      if (deleted) {
        onCancel();
        onDeleteAppointment();
      }
    }
  };

  return (
    <div className="manage-container">
      <div id="progressHeader" className={`progressHeader`}>
        <div className="progressContainer">
          <div className="progressTitle create-title">Appointment details</div>
        </div>
      </div>
      <div className="manage-content">
        <form className="form">
          {/* DATE - TIME */}
          <fieldset disabled={deleteAlert} className="card">
            <div className="card-header">
              <h3>Select date and time</h3>
            </div>
            <div className="form-items">
              <div className="form-item">
                <label>
                  Date <span className="field__required">*</span>
                </label>
                <DatePicker
                  className={`form-input-field ${
                    invalid.date ? 'form-input-field__error' : ''
                  }`}
                  selected={
                    formData.date
                      ? new Date(
                          moment(
                            formData.date,
                            'YYYY-MM-DDTHH:mm:ss[Z]',
                          ).format(),
                        )
                      : new Date()
                  }
                  onChange={handleDateChange}
                  minDate={new Date()}
                  dateFormat="M/d/yyyy"
                  placeholderText="Select Date"
                />
                {invalid.date && (
                  <span className="error-message">{invalid.date}</span>
                )}
              </div>
              <div className="form-item">
                <label>
                  Time <span className="field__required">*</span>
                </label>
                <DatePicker
                  className={`form-input-field ${
                    invalid.time ? 'form-input-field__error' : ''
                  }`}
                  selected={
                    formData.time
                      ? new Date(
                          moment(formData.time, 'YYYY-MM-DDTHH:mm:ss[Z]')
                            .local(true)
                            .format(),
                        )
                      : undefined
                  }
                  onChange={handleTimeChange}
                  showTimeSelect
                  showTimeSelectOnly
                  dateFormat={'p'}
                  timeCaption="Time"
                  placeholderText="Select Time"
                />
                {invalid.time && (
                  <span className="error-message">{invalid.time}</span>
                )}
              </div>
            </div>
          </fieldset>
          {/* PROVIDER */}
          <fieldset className="card">
            <div className="card-header">
              <h3>Healthcare provider details</h3>
            </div>
            {/* OPTIONS */}
            {providers.length > 0 && (
              <div className={`provider-options ${providerEditMode}`}>
                {providers.map(provider => {
                  const title = provider.first_name + ' ' + provider.last_name;
                  return (
                    <ProviderOption
                      title={title}
                      checked={selectedProvider?.id === provider.id}
                      onSelect={() => selectProvider(provider.id)}
                      onEditPress={() => onEditProviderPress(provider)}
                      key={provider.id}
                    />
                  );
                })}
                {!providerEditMode && (
                  <ProviderAddButton
                    title="Add provider"
                    onAddPress={onAddProviderPress}
                  />
                )}
              </div>
            )}
            {/* FORM */}
            {providerEditMode && (
              <>
                {providerEditMode === 'add' && <h4>Add Provider</h4>}
                <div className="form-items">
                  <div className="form-item">
                    <label>
                      Provider First Name{' '}
                      <span className="field__required">*</span>
                    </label>
                    <Input
                      name="provider_first_name"
                      inputType="text"
                      content={formData.provider_first_name}
                      controlFunc={e =>
                        handleInputChange('provider_first_name', e)
                      }
                      placeholder="Enter Provider's First Name"
                      maxLength={`${`${MAX_INPUT_LENGTH}`}`}
                      errorClass={
                        invalid.provider_first_name
                          ? 'form-input-field__error'
                          : ''
                      }
                    />
                    {invalid.provider_first_name && (
                      <span className="error-message">
                        {invalid.provider_first_name}
                      </span>
                    )}
                  </div>
                  <div className="form-item">
                    <label>
                      Provider Last Name{' '}
                      <span className="field__required">*</span>
                    </label>
                    <Input
                      name="provider_last_name"
                      inputType="text"
                      content={formData.provider_last_name}
                      controlFunc={e =>
                        handleInputChange('provider_last_name', e)
                      }
                      placeholder="Enter Provider's Last Name"
                      maxLength={`${MAX_INPUT_LENGTH}`}
                      errorClass={
                        invalid.provider_last_name
                          ? 'form-input-field__error'
                          : ''
                      }
                    />
                    {invalid.provider_last_name && (
                      <span className="error-message">
                        {invalid.provider_last_name}
                      </span>
                    )}
                  </div>
                </div>
                {providerEditMode === 'new' && (
                  <div className="form-items">
                    <div className="form-item checkbox">
                      <label>
                        <input
                          className="form-input-checkbox"
                          type="checkbox"
                          checked={saveProvider}
                          onChange={e => setSaveProvider(e.target.checked)}
                        />{' '}
                        Save as provider contact
                      </label>
                    </div>
                  </div>
                )}
                {providerEditMode === 'add' && (
                  <div className="provider-edition-buttons">
                    <div
                      className="provider-edition-button"
                      onClick={onCancelAddProviderPress}>
                      Cancel
                    </div>
                    <div
                      className="provider-edition-button"
                      onClick={onSaveNewProvider}>
                      Save Provider
                    </div>
                  </div>
                )}
                {providerEditMode === 'edit' && (
                  <div className="provider-edition-buttons">
                    <div
                      className="provider-edition-button remove"
                      onClick={onRemoveProviderPress}>
                      Remove Provider
                    </div>
                    <div className="provider-edition-spacer" />
                    <div
                      className="provider-edition-button"
                      onClick={onCancelAddProviderPress}>
                      Cancel
                    </div>
                    <div
                      className="provider-edition-button"
                      onClick={onUpdateProvider}>
                      Save Provider
                    </div>
                  </div>
                )}
              </>
            )}
          </fieldset>
          {/* ADDRESS */}
          <fieldset
            className={`card collapsible ${showAddress ? 'active' : ''}`}
            style={{ overflow: 'visible' }}>
            <div
              className="card-header"
              onClick={() => setShowAddress(!showAddress)}>
              <h3>Provider address (Optional)</h3>
              <div className="card-header-button">
                <Unicons.UilAngleDown size="24" />
              </div>
            </div>
            <div className="card-content overflow">
              {/* OPTIONS */}
              {selectedProvider && selectedProvider.addresses.length > 0 && (
                <div className={`provider-options ${addressEditMode}`}>
                  {selectedProvider.addresses.map(address => {
                    const title = `${address.address_1} ${address.address_2}`;
                    const subtitle = `${address.city}${
                      address.state ? `, ${address.state} ${address.zip}` : ``
                    }`;
                    return (
                      <ProviderOption
                        title={title}
                        subtitle={subtitle}
                        checked={selectedAddress?.id === address.id}
                        onSelect={() => selectAddress(address.id)}
                        onEditPress={() => onEditAddressPress(address)}
                        default_address={address.address_default}
                        key={address.id}
                      />
                    );
                  })}
                  {!addressEditMode && (
                    <ProviderAddButton
                      title="Add address"
                      onAddPress={onAddAddressPress}
                    />
                  )}
                </div>
              )}
              {/* FORM */}
              {addressEditMode && (
                <>
                  {addressEditMode === 'add-address' && <h4>Add Address</h4>}
                  {addressEditMode === 'edit' && <h4>Edit Address</h4>}
                  <div className="form-items">
                    <div className="form-item">
                      <label>Street name 1</label>
                      <Input
                        name="address_1"
                        inputType="text"
                        content={formData.provider_address_1}
                        controlFunc={e =>
                          handleInputChange('provider_address_1', e)
                        }
                        placeholder="Enter Street Address"
                        maxLength={`${MAX_INPUT_LENGTH}`}
                      />
                    </div>
                    <div className="form-item">
                      <label>Street name 2</label>
                      <Input
                        name="address_2"
                        inputType="text"
                        content={formData.provider_address_2}
                        controlFunc={e =>
                          handleInputChange('provider_address_2', e)
                        }
                        placeholder="Enter Street Address (optional)"
                        maxLength={`${MAX_INPUT_LENGTH}`}
                      />
                    </div>
                    <div className="form-item">
                      <label>City</label>
                      <Input
                        name="city"
                        inputType="text"
                        content={formData.provider_city}
                        controlFunc={e => handleInputChange('provider_city', e)}
                        placeholder="Enter City"
                        maxLength={`${MAX_INPUT_LENGTH}`}
                      />
                    </div>
                    <div className="form-item">
                      <label>State</label>
                      <Select
                        className="form-select-wrapper form-item-select"
                        classNamePrefix="form-select"
                        instanceId={3}
                        value={
                          formData.provider_state
                            ? findStateOption(formData.provider_state)
                            : undefined
                        }
                        onChange={e => handleInputChange('provider_state', e)}
                        options={config.states}
                        placeholder="Select State"
                      />
                    </div>
                    <div className="form-item">
                      <label>Zip</label>
                      <Input
                        name="zip"
                        inputType="text"
                        content={formData.provider_zip}
                        controlFunc={e => handleInputChange('provider_zip', e)}
                        placeholder="Enter Zip"
                        maxLength={'5'}
                      />
                    </div>
                    <div className="form-item">
                      <label>Phone Number</label>
                      <Input
                        name="phone"
                        inputType="tel"
                        inputmode="numeric"
                        pattern="[0-9]*"
                        content={formData.provider_phone}
                        controlFunc={e =>
                          handleInputChange('provider_phone', e)
                        }
                        placeholder="Enter Phone Number"
                        maxLength={'10'}
                      />
                    </div>
                  </div>
                  <div className="form-items">
                    {addressEditMode === 'new' && (
                      <div className="form-item checkbox">
                        <label className="form-input-label text__left">
                          <input
                            className="form-input-checkbox"
                            type="checkbox"
                            checked={saveProviderAddress}
                            onChange={e =>
                              setSaveProviderAddress(e.target.checked)
                            }
                          />{' '}
                          Save provider address
                        </label>
                      </div>
                    )}
                    {(addressEditMode === 'edit' ||
                      addressEditMode === 'add-address') && (
                      <div className="form-item checkbox">
                        <label>
                          <input
                            className="form-input-checkbox"
                            type="checkbox"
                            checked={formData.address_default}
                            onChange={e =>
                              setSaveDefaultAddress(e.target.checked)
                            }
                          />{' '}
                          Save as default address
                        </label>
                      </div>
                    )}
                  </div>
                  {/* BUTTONS */}
                  {addressEditMode === 'add-address' && (
                    <div className="provider-edition-buttons">
                      <div
                        className="provider-edition-button"
                        onClick={onCancelAddAddressPress}>
                        Cancel
                      </div>
                      <div
                        className="provider-edition-button"
                        onClick={onSaveNewAddress}>
                        Save Address
                      </div>
                    </div>
                  )}
                  {addressEditMode === 'edit' && (
                    <div className="provider-edition-buttons">
                      <div
                        className="provider-edition-button remove"
                        onClick={onRemoveAddressPress}>
                        Remove Address
                      </div>
                      <div className="provider-edition-spacer" />
                      <div
                        className="provider-edition-button"
                        onClick={onCancelAddAddressPress}>
                        Cancel
                      </div>
                      <div
                        className="provider-edition-button"
                        onClick={onUpdateAddress}>
                        Save Address
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </fieldset>
          {/* NOTES */}
          <fieldset className={`card collapsible ${showNotes ? 'active' : ''}`}>
            <div
              className="card-header"
              onClick={() => setShowNotes(!showNotes)}>
              <h3>Notes (Optional)</h3>
              <div className="card-header-button">
                <Unicons.UilAngleDown size="24" />
              </div>
            </div>
            <div className="card-content">
              <TextArea
                className="notes"
                name="notes"
                content={formData.notes}
                controlFunc={e => handleInputChange('notes', e)}
                placeholder="Add notes"
                maxLength={`${MAX_NOTES_LENGTH}`}
                rows={100}></TextArea>
              <small>
                Characters left: {MAX_NOTES_LENGTH - formData.notes.length}
              </small>
            </div>
          </fieldset>
        </form>
      </div>
      <div className="alert-space">
        {errorMessage && <ErrorAlert message={errorMessage} />}
      </div>
      <div className="footer-buttons-bar">
        <div className="buttons-container">
          {appointmentToEdit?.id ? (
            <Button
              className="button button__secondary100-inverted button__btn-14-600 button-rectangle-skin-footer"
              onClick={onDeletAppointmentPress}
              text="Delete"
            />
          ) : (
            <Button
              className="button button__secondary100-inverted button__btn-14-600 button-rectangle-skin-footer"
              onClick={onCancel}
              text="Cancel"
            />
          )}
          <Button
            onClick={createAppointment}
            className="button button__secondary100 button__btn-14-600 button-rectangle-skin-footer"
            text={saving ? 'Saving...' : 'Save'}
            disabled={saving}
          />
        </div>
      </div>
    </div>
  );
};

export default AppointmentForm;
