import React, { useRef } from 'react';

import Benefit1Icon from '../../../assets/images/hcp-landing/benefit-1.svg';
import Benefit2Icon from '../../../assets/images/hcp-landing/benefit-2.svg';
import Benefit3Icon from '../../../assets/images/hcp-landing/benefit-3.svg';
import Benefit4Icon from '../../../assets/images/hcp-landing/benefit-4.svg';
import Benefit5Icon from '../../../assets/images/hcp-landing/benefit-5.svg';
import { useFirstTimeVisible } from '../../../components/shared/hooks/useFirstTimeVisible';

const EczemawiseBenefits = ({ onSectionIsVisible }) => {
  const ref = useRef();
  const isVisible = useFirstTimeVisible(ref, onSectionIsVisible);

  return (
    <div className="eczemawise-features benefits">
      <h2>
        How does EczemaWise <span>benefit</span>
        <br /> me/my practice?
      </h2>
      <div className="features">
        <div className="feature benefit">
          <div className="feature-info">
            <img src={Benefit1Icon} alt="" />
            <p>
              <strong>
                Improve the efficiency and effectiveness of patient visits.
              </strong>
            </p>
          </div>
          <p>
            EczemaWise helps patients manage their condition and adhere to
            treatments outside the office. It provides you with accurate,
            comprehensive health data through validated patient-reported
            outcomes, reducing recall bias during appointments.
          </p>
        </div>
        <div className="feature benefit">
          <div className="feature-info">
            <img src={Benefit2Icon} alt="" />
            <p>
              <strong>Have more focused conversations.</strong>
            </p>
          </div>
          <p>
            EczemaWise's appointment prep tool prepares patients for visits,
            allowing for more targeted discussions. The app also educates
            patients about their eczema with articles from the National Eczema
            Association.
          </p>
        </div>
        <div className="feature benefit" ref={ref}>
          <div className="feature-info">
            <img src={Benefit3Icon} alt="" />
            <p>
              <strong>Quickly assess patient needs.</strong>
            </p>
          </div>
          <p>
            A patient-shared summary of recent symptoms helps you determine the
            urgency of scheduling an appointment.
          </p>
        </div>
        <div className="feature benefit">
          <div className="feature-info">
            <img src={Benefit4Icon} alt="" />
            <p>
              <strong>Ensure continuity of care.</strong>
            </p>
          </div>
          <p>
            Requesting an EczemaWise summary from your patients keeps you
            informed of their progress, allowing you to determine if and when
            follow-up is needed.
          </p>
        </div>
        <div className="feature benefit">
          <div className="feature-info">
            <img src={Benefit5Icon} alt="" />
            <p>
              <strong>Simplify pre-authorization paperwork.</strong>
            </p>
          </div>
          <p>
            EczemaWise data can support pre-authorization by demonstrating
            treatment effectiveness and patient severity, helping you secure the
            necessary treatments for your patients.
          </p>
        </div>
      </div>
    </div>
  );
};

export default EczemawiseBenefits;
