import { flowRight as compose } from 'lodash';
import React, { Component } from 'react';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import analytics from '../../libs/analytics';
import MixpanelService from '../../libs/mixpanel';
import PasswordInputWithToggle from '../shared/PasswordInputWithToggle';
import FormItem from '../shared/FormItem';
import WarningIcon from '../../assets/icons/warningIcon.svg';

class DeleteAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user_delete: '',
      password_delete: '',
      deleteUserFail: false,
      showPassword: false,
    };
  }

  togglePasswordVisibility = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  };

  render() {
    return (
      <>
        <h3 className="title">
          Delete Your Account
        </h3>
        <p className='description'>
          This action will delete your username and email from EczemaWise.
        </p>
        <p className="warning">
          This action cannot be undone. You'll need to create a new account to
          return to EczemaWise.
        </p>
        <div class="image-text">
          <img src={WarningIcon} alt="warning icon"/>
          <p>
            We’re sorry to see you go! <br/>
            Are you sure you don’t want to track your eczema? 
          </p>
        </div>
        <br/>
        <p className='description'>
          You can sign up for Tracker Notifications under Settings to help you remember!  Just go to Account Settings → Notifications  to turn on Tracker Notifications. 
        </p>
        <p className='description'>
          You’ll also find tutorials throughout the app if you need extra help. Just click on the question mark icon at the top of every page.        
        </p>
        
        <form className="form">
            {this.state.deleteUserFail ? (
              <div className="alert">
                <h3 className="headers">
                  Error deleting user. Please try again.
                </h3>
              </div>
            ) : null}
            <FormItem
              label='Username'
              required
              placeholder='User'
              value={this.state.user_delete}
              onChange={(value) => this._updateInput('user_delete', value)}
            />
            <PasswordInputWithToggle
              label='Confirm your Password'
              value={this.state.password_delete}
              onChange={(value) => this._updateInput('password_delete', value)}
            />
          </form>
          <div className="form-item-right card-footer">
            <button
              className="button button__secondary100-inverted  button__btn-14-600 button-rectangle-medium"
              disabled={ !this.state.password_delete || !this.state.user_delete }
              onClick={this.deleteUser.bind(this)}
              >
              Delete account
            </button>
            <button
              className="button button__secondary100 button__btn-14-600 button-rectangle-medium"
              onClick={()=>this._updateInput('password_delete', '')}
              >
              Cancel
            </button>
          </div>
      </>
    );
  }

  _updateInput(name, value) {
    let newState = {};
    newState[name] = value;

    if (name === 'password_delete' && this.state.deleteUserFail) {
      newState.deleteUserFail = false;
    }

    this.setState(newState);
  }

  deleteUser(e) {
    e.preventDefault();
    const password = this.state.password_delete;
    const username = this.state.user_delete;
    return this.props
      .deleteAccount({
        variables: {
          password,
          username,
        },
      })
      .then(() => {
        //delete token and redirect home
        this.props.client.resetStore().then(() => {
          this.props.history.replace('/');
        });
        MixpanelService.track('DeleteAccount', { 
          AccountDeleted: true
        });
        analytics().logEvent('delete_your_account', {});
      })
      .catch(e => {
        this.setState({
          deleteUserFail: true,
          password_delete: '',
        });
        MixpanelService.track('DeleteAccount', { AccountDeleted: false });
      });
  }
}

export default compose(withRouter, withApollo)(DeleteAccount);
