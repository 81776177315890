import React, { useRef } from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import HowToUseHero from '../../../assets/images/hcp-landing/how-to-use-hero.webp';
import { useFirstTimeVisible } from '../../../components/shared/hooks/useFirstTimeVisible';

const HowToUse = ({ onPressDownload, onSectionIsVisible }) => {
  const ref = useRef();
  const isVisible = useFirstTimeVisible(ref, onSectionIsVisible);

  return (
    <div className="how-to-use">
      <div className="how-to-use-info">
        <h2>
          How can I <span>use</span> EczemaWise with my patient?
        </h2>
        <p>
          EczemaWise works best when both you and your patient are engaged.{' '}
          <a
            href="javascript:void(0)"
            onClick={e => {
              e.preventDefault();
              onPressDownload();
            }}>
            Download our Guide for HCPs
          </a>{' '}
          on using EczemaWise with your patients. In it, we provide step-by-step
          guidance on how EczemaWise can serve as a useful tool for you, your
          patient, and your practice.
        </p>
        <p ref={ref}>
          <Link to="/help" className="help-link">
            Refer to the EczemaWise FAQs for more information.
          </Link>
        </p>
      </div>
      <div className="how-to-use-hero">
        <img src={HowToUseHero} alt="" />
      </div>
    </div>
  );
};

export default HowToUse;
