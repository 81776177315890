import { createSlice } from '@reduxjs/toolkit';
import { fetchAppointments } from './appointments-thunks';
import moment from 'moment-timezone';

const initialState = {
  appointments: [],
  upcomingAppointments: [],
  pastAppointments: [],
  loading: false,
  error: null,
};

const getUpcomingAppointments = appointments => {
  const today = moment().startOf('day');
  return appointments.filter(appointment => {
    const appointmentDate = moment(appointment.date, 'YYYY-MM-DDTHH:mm:ss[Z]');
    return appointmentDate.isSameOrAfter(today);
  });
};

const getPastAppointments = appointments => {
  const today = moment().startOf('day');
  return appointments.filter(appointment => {
    const appointmentDate = moment(appointment.date, 'YYYY-MM-DDTHH:mm:ss[Z]');
    return appointmentDate.isBefore(today);
  });
};

const sortAppointments = appointments => {
  const sortedAppointments = appointments.sort((a, b) => {
    const aDate = moment(a.date, 'YYYY-MM-DDTHH:mm:ss[Z]');
    const bDate = moment(b.date, 'YYYY-MM-DDTHH:mm:ss[Z]');
    if (!aDate.isSame(bDate, 'day')) {
      return aDate.isBefore(bDate, 'day') ? -1 : 1;
    }
    const aTime = moment(a.time, 'YYYY-MM-DDTHH:mm:ss[Z]');
    const bTime = moment(b.time, 'YYYY-MM-DDTHH:mm:ss[Z]');
    return aTime.isBefore(bTime, 'minute') ? -1 : 1;
  });
  return sortedAppointments;
};

const appointmentsSlice = createSlice({
  name: 'manage',
  initialState,
  reducers: {
    setAppointments(state, action) {
      const appointments = action.payload;
      const upcomingAppointments = sortAppointments(
        getUpcomingAppointments(appointments),
      );
      const pastAppointments = sortAppointments(
        getPastAppointments(appointments),
      );

      state.appointments = appointments;
      state.upcomingAppointments = upcomingAppointments;
      state.pastAppointments = pastAppointments;
    },
    updateAppointment(state, action) {
      state.appointments = state.appointments.map(appointment => {
        if (appointment.id === action.payload.id) {
          return {
            ...appointment,
            ...action.payload,
          };
        }
        return appointment;
      });
    },
    deleteAppointment(state, action) {
      state.appointments = state.appointments.filter(
        appointment => appointment.id !== action.payload,
      );
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchAppointments.pending, state => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchAppointments.fulfilled, (state, action) => {
      state.appointments = action.payload;
      state.loading = false;
    });
    builder.addCase(fetchAppointments.rejected, (state, action) => {
      state.error = action.error.message;
      state.loading = false;
    });
  },
});

export const appointmentActions = appointmentsSlice.actions;

export default appointmentsSlice.reducer;
