import React, { Component } from 'react';
import ValidationIcon from '../../assets/icons/validation';
import analytics from '../../libs/analytics';
import MixpanelService from '../../libs/mixpanel';
import passwordValidation from '../../libs/passwordValidation';
import PasswordInputWithToggle from '../shared/PasswordInputWithToggle';

const passwordRulesMessages = {
  length: '8 characters',
  upper: '1 uppercase letter',
  lower: '1 lowercase letter',
  special: '1 special character',
  num: '1 number'
};

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rulesTracker: {},
      password: '',
      new_password: '',
      confirm_new_password: '',
      changePasswordStatus: {},
    };
  }

  render() {
    return (
      <>
        <div className="card-section section__password">
          <h3 className="title">
            Change your password
          </h3>
          <p className='description'>
            Change the password you use to login and out of EczemaWise.
          </p>
          <div className="card-section section__inner-password">
          <div className="section-half section__delete">
            <form className="form">
              {this.state.changePasswordStatus &&
              this.state.changePasswordStatus.message ? (
                <div className="alert">
                  <h3 className="headers">
                    {this.state.changePasswordStatus.message}
                  </h3>
                </div>
              ) : null}
              <PasswordInputWithToggle
                label={'Current Password'}
                value={this.state.password}
                onChange={(value) => this._updateInput('password', value)}
              />
              <PasswordInputWithToggle
                label={'New Password'}
                value={this.state.new_password}
                onChange={(value) => this._updateInput('new_password', value)}
              />
              <p className="form-pass-validation">
                Password must have at least: {this._rulesCheck()}
              </p>
              <PasswordInputWithToggle
                label={'Confirm New Password'}
                value={this.state.confirm_new_password}
                onChange={(value) => this._updateInput('confirm_new_password', value)}
              />
              <p className="form-pass-validation">
                {this.state.rulesTracker['match'] ? '' : "Passwords don't match."}
              </p>
            </form>
          </div>          
          </div>
        </div>
        <div className="form-item-right card-footer">
          <button
            className="button button__secondary100-inverted  button__btn-14-600 button-rectangle-medium"
            onClick={this._clearInputs.bind(this)}>
            Cancel
          </button>
          <button
            className="button button__secondary100 button__btn-14-600 button-rectangle-medium"
            onClick={this.changePassword.bind(this)}
            disabled={this._checkButtonState() || !this.state.password}>
            Save
          </button>
        </div>
      </>
    );
  }

  changePassword(e) {
    e.preventDefault();
    return this.props
      .changePassword({
        variables: {
          new_password: this.state.new_password,
          old_password: this.state.password,
        },
      })
      .then(res => {
        // changing password requires re-auth with cognito and a new token is returned from server
        let newToken = res.data.changePassword;
        this.setState({
          changePasswordStatus: {
            success: true,
            message: 'Password updated.',
          },
          password: '',
          new_password: '',
          confirm_new_password: '',
          rulesTracker: {},
        });

        MixpanelService.track('PasswordChanged');
        analytics().logEvent('update_profile', {});
      })
      .catch(e => {
        this.setState({
          changePasswordStatus: {
            success: false,
            message: 'Password change failed. Please try again.',
          },
          password: '',
          new_password: '',
          confirm_new_password: '',
          rulesTracker: {},
        });
      });
  }

  _rulesCheck() {
    const rules = Object.keys(passwordRulesMessages).map(rule => {
      const isSuccess = this.state.rulesTracker[rule];
      const className = isSuccess
        ? 'form-pass-validation__passing'
        : 'form-pass-validation-item';
      return (
        <span className={className}>
          {isSuccess ? '✓ ' : '• '}
          {passwordRulesMessages[rule]}
        </span>
      );
    });

    return rules.reduce((acc, rule, index) => {
      acc.push(rule);
      if (index !== rules.length - 1) {
        acc.push(', ');
      }
      return acc;
    }, []);
  }
  
  _clearInputs(){
    this.setState({
      password: '',
      new_password: '',
      confirm_new_password: '',
    }, () => {
        this._validatePassword();
    });
  }

  _updateInput(name, value) {
    let newState = {};
    newState[name] = value;
    if (
      name === 'password' ||
      name === 'new_password' ||
      (name === 'confirm_new_password' &&
        this.state.changePasswordStatus.message)
    ) {
      newState.changePasswordStatus = {};
    } else if (this.state.changePasswordStatus.status) {
      newState.changePasswordStatus = {};
    }

    this.setState(newState, () => {
      if (name === 'new_password' || name === 'confirm_new_password') {
        this._validatePassword();
      }
    });
  }

  _validatePassword() {
    let password = this.state.new_password;
    let password_confirm = this.state.confirm_new_password;
    let rulesTestResults = passwordValidation(password, password_confirm);
    this.setState({
      rulesTracker: rulesTestResults,
    });

    return (
      rulesTestResults.match &&
      rulesTestResults.length &&
      rulesTestResults.num &&
      rulesTestResults.special &&
      rulesTestResults.upper &&
      rulesTestResults.lower
    );
  }

  _checkButtonState() {
    let { rulesTracker } = this.state;
    return !(
      rulesTracker.match &&
      rulesTracker.length &&
      rulesTracker.num &&
      rulesTracker.special &&
      rulesTracker.upper &&
      rulesTracker.lower
    );
  }


}

export default ChangePassword;
