import React, { useEffect, useState } from 'react';

import {
  AccessData,
  DownloadGuide,
  DownloadModal,
  EczemaMadeEasy,
  EczemawiseBenefits,
  EczemawiseFeatures,
  HowToUse,
  MeetEczemawise,
  ResourceHub,
  WhoDeveloped,
} from './components';

import MixpanelService, { MixpanelEvents } from '../../libs/mixpanel';

const Landing = () => {
  const [showModal, setShowModal] = useState(false);
  const [progress, setProgress] = useState(0);

  const onPressDownload = () => {
    setShowModal(true);
  };

  const trackProgress = percentage => {
    if (percentage > progress) {
      MixpanelService.track(MixpanelEvents.PageProgressHCP, {
        percentage,
      });
      setProgress(percentage);
    }
  };

  useEffect(() => {
    MixpanelService.trackPageView('HCP');
  }, []);

  return (
    <>
      <div className="hcp-landing">
        <MeetEczemawise
          onSectionIsVisible={() => trackProgress(15)}
        />
        <EczemawiseFeatures
          onSectionIsVisible={() => trackProgress(25)}
        />
        <EczemaMadeEasy
          onSectionIsVisible={() => trackProgress(40)}
        />
        <ResourceHub
          onSectionIsVisible={() => trackProgress(50)}
        />
        <HowToUse
          onPressDownload={onPressDownload}
          onSectionIsVisible={() => trackProgress(60)}
        />
        <DownloadGuide
          onPressDownload={onPressDownload}
          onSectionIsVisible={() => trackProgress(70)}
        />
        <AccessData
          onSectionIsVisible={() => trackProgress(80)}
        />
        <EczemawiseBenefits
          onSectionIsVisible={() => trackProgress(90)}
        />
        <WhoDeveloped
          onSectionIsVisible={() => trackProgress(100)}
        />
      </div>
      {showModal && <DownloadModal onClose={() => setShowModal(false)} />}
    </>
  );
};

export default Landing;
