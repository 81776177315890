import React from 'react';
import * as Unicons from '@iconscout/react-unicons';
import ModalHero1 from '../../../assets/images/hcp-landing/resource-hub-hero1.svg';
import ModalHero2 from '../../../assets/images/hcp-landing/resource-hub-hero2.svg';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { createHCProviderSF } from '../../../libs/graphql/mutations/HCP';
import { useMutation } from 'react-apollo';
import MixpanelService, { MixpanelEvents } from '../../../libs/mixpanel';

const DownloadModal = ({ onClose }) => {
  const [success, setSuccess] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [selection, setSelection] = React.useState('');
  const [zip, setZip] = React.useState('');
  const [checked, setChecked] = React.useState(false);
  const [downloading, setDownloading] = React.useState(false);
  const [downloaded, setDownloaded] = React.useState(false);

  // APOLLO
  const [createHCProviderSFMutation] = useMutation(createHCProviderSF);

  const onChangeZipCode = value => {
    // validate only numbers
    const regex = /^\d+$/;
    if (regex.test(value)) {
      setZip(value);
    }
  };

  const submitForm = async () => {
    setError('');
    if (zip.length !== 5) {
      setError('Please enter a valid zip code');
      return;
    }

    setLoading(true);

    try {
      const HCProvider = {
        email,
        relation_to_eczema: selection,
        zip,
        consent: checked,
      };

      await createHCProviderSFMutation({
        variables: { HCProvider },
        errorPolicy: 'all',
      });

      setSuccess(true);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError('Please try again');
    }
  };

  const trackDownloadClick = () => {
    MixpanelService.track(MixpanelEvents.GuideDownloadTappedHCP);
  };

  const onPressDownload = e => {
    if (downloading || downloaded) {
      e.preventDefault();
      return;
    }

    trackDownloadClick();
    setDownloading(true);

    setTimeout(() => {
      setDownloading(false);
      setDownloaded(true);
    }, 3000);
  };

  const downloadDisabled = !success || downloading || downloaded;
  const downloadLink =
    process.env.NODE_ENV === 'development'
      ? `http://localhost:8060/api/docs/for-healthcare-providers.pdf`
      : `/api/docs/for-healthcare-providers.pdf`;

  return (
    <div className="download-guide-modal-overlay">
      <div className="download-guide-modal">
        <span className="close-button" onClick={onClose}>
          <Unicons.UilTimes size="28" />
        </span>
        {!success ? (
          <form
            className="download-form"
            onSubmit={e => {
              e.preventDefault();
              submitForm();
            }}>
            <h3>
              Enter your contact
              <br />
              information to access
            </h3>
            <div className="form-group">
              <label>
                Email Address <span>*</span>
              </label>
              <input
                className="input-field"
                type="email"
                placeholder="mail@mail.com"
                required
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>
                Select one <span>*</span>
              </label>
              <select
                className="input-field"
                required
                value={selection}
                onChange={e => setSelection(e.target.value)}>
                <option value="" disabled>
                  Select
                </option>
                <option value="Adult with eczema">
                  I am an adult with eczema (18 and older)
                </option>
                <option value="Caregiver/Partner/Spouse">
                  I am the caregiver/partner/spouse of someone with eczema
                </option>
                <option value="Parent of child with eczema">
                  I am the parent of a child with eczema
                </option>
                <option value="Health Professional">
                  I am an eczema healthcare professional
                </option>
                <option value="Corporate Interest">Corporate Interest</option>
              </select>
            </div>
            <div className="form-group">
              <label>
                Zip code <span>*</span>
              </label>
              <input
                className="input-field"
                type="text"
                placeholder="Select"
                maxLength={5}
                required
                value={zip}
                onChange={e => onChangeZipCode(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>
                <input
                  type="checkbox"
                  checked={checked}
                  onChange={e => setChecked(e.target.checked)}
                  required
                />{' '}
                I have read and agree to the{' '}
                <Link to="/privacy-policy" target="_blank">
                  NEA privacy policy
                </Link>{' '}
                and agree to receive communications from NEA. <span>*</span>
              </label>
            </div>
            <button type="submit" disabled={loading}>
              {loading ? 'Sending...' : 'Submit'}
            </button>
            {error && (
              <div className="error-message">
                <Unicons.UilExclamationTriangle size="18" />
                <span>{error}</span>
              </div>
            )}
          </form>
        ) : (
          <div className="download-form">
            <h3>You can now download the guide below.</h3>
            <a
              href={downloadLink}
              className="download"
              onClick={onPressDownload}
              download>
              <button disabled={downloadDisabled}>
                {downloading
                  ? 'Downloading...'
                  : downloaded
                  ? 'Thanks for downloading!'
                  : 'Download'}
              </button>
            </a>
          </div>
        )}
        <div className="modal-hero">
          <img className="modal-hero-1" src={ModalHero1} alt="" />
          <img className="modal-hero-2" src={ModalHero2} alt="" />
        </div>
      </div>
    </div>
  );
};

export default DownloadModal;
