
import eHealthcareAward22 from '../../assets/images/newBrandImages/eHealthcareAward22.png';
import eHealthcareAward23 from '../../assets/images/newBrandImages/eHealthcareAward23.png';
import LogoWinnerBadge from '../../assets/images/newBrandImages/LogoWinnerBadge.png';

function Awards() {
  return (
    <section className="home-page__awards-section">
      <div className="content-wrapper">
        <div className="content-section">
          <h2 className="title__homepage-large title__primary100">
            <span className="text__secondary100">Awards{' '}</span>
            and Recognition 
          </h2>
          <p className="paragraph">
            We’re proud to provide an app that can help make eczema management 
            just a little bit easier for our community.
          </p>
          <div className="awards-container">
            <div className="award-container">
              <div className="img-container">
                <img src={LogoWinnerBadge} alt="" />
              </div>
              <div className="text-container">
                <span className="text_black">eHealthcare:</span>
                <span className="text_black">Double Platinum</span>
                <span className="text_grey">2024</span>
              </div>
            </div>
            <div className="award-container">
              <div className="img-container">
                <img src={eHealthcareAward22} alt="" />
              </div>
              <div className="text-container">
                <span className="text_black">eHealthcare:</span>
                <span className="text_black">Double Platinum</span>
                <span className="text_grey">2023</span>
              </div>
            </div>
            <div className="award-container">
              <div className="img-container">
                <img src={eHealthcareAward23} alt="" />
              </div>
              <div className="text-container">
                <span className="text_black">eHealthcare:</span>
                <span className="text_black">Double Platinum</span>
                <span className="text_grey">2022</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Awards;
