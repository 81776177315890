import { useState, useEffect } from 'react';

export function useFirstTimeVisible(ref, onIntersect) {
  const [hasIntersected, setHasIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (!entry.isIntersecting || hasIntersected) {
        return;
      }
      setHasIntersecting(entry.isIntersecting);
      onIntersect();
    });

    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [ref, hasIntersected, onIntersect]);

  return hasIntersected;
}
