import React from 'react';

export default function Topical() {
  return (
    <svg viewBox="0 0 18 28">
      <g
        className="icon-fill"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd">
        <path
          d="M5.97703077,25.3076923 C6.22687692,24.08 7.31456923,23.1538462 8.61549231,23.1538462 C9.91533846,23.1538462 11.0030308,24.08 11.2528769,25.3076923 L15.0770308,25.3076923 L15.0770308,21 L2.15395385,21 L2.15395385,25.3076923 L5.97703077,25.3076923 Z"
          id="Fill-1"
        />
        <path
          d="M10.7690154,26.3846154 C10.4717846,26.3846154 10.2305538,26.1433846 10.2305538,25.8461538 C10.2305538,24.9555385 9.50578462,24.2307692 8.61516923,24.2307692 C7.72455385,24.2307692 6.99978462,24.9555385 6.99978462,25.8461538 C6.99978462,26.1433846 6.75855385,26.3846154 6.46132308,26.3846154 L2.15363077,26.3846154 L2.15363077,27.7307692 C2.15363077,27.8793846 2.27424615,28 2.42286154,28 L14.8074769,28 C14.9560923,28 15.0767077,27.8793846 15.0767077,27.7307692 L15.0767077,26.3846154 L10.7690154,26.3846154 Z"
          id="Fill-3"
        />
        <path
          d="M7.88221538,7.63678462 L6.48221538,7.63678462 L6.48221538,9.32647692 L7.88221538,9.32647692 C8.44329231,9.32647692 8.82129231,8.98724615 8.82129231,8.48216923 C8.82129231,7.97601538 8.44329231,7.63678462 7.88221538,7.63678462"
          id="Fill-5"
        />
        <path
          d="M11.8238615,15.0774615 L10.7921692,15.0774615 C10.7469385,15.0774615 10.7049385,15.0548462 10.6801692,15.0182308 L9.70016923,13.5665385 L8.71263077,15.0182308 C8.68786154,15.0548462 8.64586154,15.0774615 8.60170769,15.0774615 L7.60447692,15.0774615 C7.55386154,15.0774615 7.50863077,15.0494615 7.48493846,15.0053077 C7.46124615,14.9611538 7.46447692,14.9073077 7.49355385,14.8663846 L8.93986154,12.7674615 L7.38693846,10.5005385 L6.48232308,10.5005385 L6.48232308,12.593 C6.48232308,12.6673077 6.42201538,12.7276154 6.34770769,12.7276154 L5.28263077,12.7276154 C5.20832308,12.7276154 5.14801538,12.6673077 5.14801538,12.593 L5.14801538,6.59669231 C5.14801538,6.52238462 5.20832308,6.46207692 5.28263077,6.46207692 L8.0794,6.46207692 C9.33832308,6.46207692 10.1847846,7.27407692 10.1847846,8.48130769 C10.1847846,9.47207692 9.65924615,10.1279231 8.66632308,10.3766923 L9.71416923,11.9393077 L10.6047846,10.5996154 C10.6295538,10.563 10.6715538,10.5403846 10.7167846,10.5403846 L11.7226308,10.5403846 C11.7721692,10.5403846 11.8184769,10.5683846 11.8421692,10.6125385 C11.8647846,10.6566923 11.8615538,10.7094615 11.8335538,10.7503846 L10.4572462,12.747 L11.9347846,14.8663846 C11.9627846,14.9073077 11.9660154,14.9611538 11.9434,15.0053077 C11.9197077,15.0494615 11.8734,15.0774615 11.8238615,15.0774615 M16.9618615,0.000538461538 L0.269553846,0.000538461538 C0.119861538,0.000538461538 0.000323076923,0.120076923 0.000323076923,0.269769231 L0.000323076923,2.15438462 L2.09063077,19.9236154 L15.1407846,19.9236154 L17.2310923,2.15438462 L17.2310923,0.269769231 C17.2310923,0.120076923 17.1104769,0.000538461538 16.9618615,0.000538461538"
          id="Fill-7"
        />
      </g>
    </svg>
  );
}
