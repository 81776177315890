import gql from 'graphql-tag';

export const forgotPassword = gql`
  mutation ForgotPassword($email: String!) {
    forgotPassword(email: $email)
  }
`;

export const login = gql`
  mutation Login($email: String!, $password: String!) {
    authenticateUser(email: $email, password: $password) {
      token
      profileFlag
      id
      email
      name
    }
  }
`;

export const resetPassword = gql`
  mutation ResetPassword($email: String!, $password: String!, $code: String!) {
    confirmForgotPassword(email: $email, password: $password, code: $code)
  }
`;

export const changePassword = gql`
  mutation ChangePassword($new_password: String!, $old_password: String!) {
    changePassword(new_password: $new_password, old_password: $old_password)
  }
`;

export const signUpUser = gql`
  mutation SignUpUser(
    $email: String!
    $password: String!
    $name: String!
    $email_consent: Boolean!
  ) {
    signUpUser(
      email: $email
      password: $password
      name: $name
      email_consent: $email_consent
    ) {
      id
    }
  }
`;

export const deleteAccount = gql`
  mutation DeleteAccount($username: String, $password: String) {
    deleteAccount(username: $username, password: $password)
  }
`;

export const adminToggleAdminUser = gql`
  mutation AdminToggleAdminUser($userid: ID!, $newStatus: Boolean) {
    adminToggleAdminUser(userid: $userid, newStatus: $newStatus)
  }
`;

export const adminUpdateUserRole = gql`
  mutation AdminUpdateUserRole($userid: ID!, $newRoleId: Int) {
    adminUpdateUserRole(userid: $userid, newRoleId: $newRoleId)
  }
`;

export const adminSendNotification = gql`
  mutation adminSendNotification($userid: ID!) {
    adminSendNotification(userid: $userid)
  }
`;

export const adminDeleteUser = gql`
  mutation AdminDeleteUser($userid: ID!) {
    adminDeleteUser(userid: $userid)
  }
`;

export const adminBlockUser = gql`
  mutation AdminBlockUser($userid: ID!, $newStatus: Boolean) {
    adminBlockUser(userid: $userid, newStatus: $newStatus)
  }
`;

export const createUserProfile = gql`
  mutation CreateUserProfile($userid: ID!, $data: ProfilePayload!) {
    createUserProfile(userid: $userid, data: $data) {
      userTreatments {
        name
      }
      persona_slug
    }
  }
`;

export const setUserDevice = gql`
  mutation setUserDevice(
    $userid: ID!
    $device_type: DEVICES!
    $token: String!
  ) {
    setUserDevice(userid: $userid, device_type: $device_type, token: $token) {
      userid
      device_type
      token
    }
  }
`;

export const setDevice = gql`
  mutation setDevice(
    $userid: ID!
    $os: String!
    $buildNumber: String!
    $lastUpdated: String!
    $appVersion: String!
    $model: String!
  ) {
    setDevice(
      userid: $userid
      os: $os
      buildNumber: $buildNumber
      appVersion: $appVersion
      model: $model
    ) {
      userid
      os
      buildNumber
      lastUpdated
      appVersion
      model
    }
  }
`;

export const updateUserZip = gql`
  mutation updateUserZip($profileid: ID!, $zip: String!) {
    updateUserZip(profileid: $profileid, zip: $zip)
  }
`;

export const updateUserTreatments = gql`
  mutation updateUserTreatments($treatments: [EditTreatmentPayload!]!) {
    editUserTreatments(treatments: $treatments) {
      id
      profile {
        id
        zip
        userTreatments {
          id
          type
          name
          active
        }
      }
    }
  }
`;

export const createUserAppointment = gql`
  mutation CreateAppointment(
    $appointmentId: ID
    $userid: ID!
    $date: String!
    $time: String!
    $notes: String
    $provider_first_name: String
    $provider_last_name: String
    $provider_phone: String
    $provider_address_1: String
    $provider_address_2: String
    $provider_city: String
    $provider_state: String
    $provider_zip: String
    $provider_id: ID
    $provider_address_id: ID
  ) {
    createAppointment(
      appointmentId: $appointmentId
      userid: $userid
      date: $date
      time: $time
      notes: $notes
      provider_first_name: $provider_first_name
      provider_last_name: $provider_last_name
      provider_phone: $provider_phone
      provider_address_1: $provider_address_1
      provider_address_2: $provider_address_2
      provider_city: $provider_city
      provider_state: $provider_state
      provider_zip: $provider_zip
      provider_id: $provider_id
      provider_address_id: $provider_address_id
    )
  }
`;

export const deleteAppointment = gql`
  mutation DeleteAppointment($appointmentId: ID!) {
    deleteAppointment(appointmentId: $appointmentId)
  }
`;

export const addAppointmentMetadata = gql`
  mutation addAppointmentMetadata($metadata: MetadataForAppointmentPayload!) {
    addAppointmentMetadata(metadata: $metadata)
  }
`;

export const createProvider = gql`
  mutation createProvider($provider: ProviderInput!) {
    createProvider(provider: $provider) {
      id
      first_name
      last_name
      addresses {
        id
        address_default
        address_1
        address_2
        phone
        city
        state
        zip
      }
    }
  }
`;

export const createProviderAddress = gql`
  mutation createProviderAddress(
    $providerId: ID!
    $address: ProviderAddressInput!
  ) {
    createProviderAddress(providerId: $providerId, address: $address) {
      address_default
      address_1
      address_2
      phone
      city
      state
      zip
      id
    }
  }
`;

export const updateProvider = gql`
  mutation updateProvider($providerId: ID!, $provider: UpdateProviderInput!) {
    updateProvider(providerId: $providerId, provider: $provider) {
      first_name
      last_name
    }
  }
`;

export const updateProviderAddress = gql`
  mutation updateProviderAddress(
    $providerId: ID!
    $addressId: ID!
    $address: ProviderAddressInput!
  ) {
    updateProviderAddress(
      providerId: $providerId
      addressId: $addressId
      address: $address
    ) {
      address_default
      address_1
      address_2
      phone
      city
      state
      zip
      id
    }
  }
`;

export const deleteProvider = gql`
  mutation deleteProvider($providerId: ID!) {
    deleteProvider(providerId: $providerId)
  }
`;

export const deleteProviderAddress = gql`
  mutation deleteProviderAddress($addressId: ID!) {
    deleteProviderAddress(addressId: $addressId)
  }
`;

export const logout = gql`
  mutation Logout($userid: ID!) {
    logout(userid: $userid)
  }
`;

export const resendConfirmationEmail = gql`
  mutation ResendConfirmationEmail($email: String!) {
    resendConfirmationEmail(email: $email)
  }
`;
