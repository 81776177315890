import React, { useRef } from 'react';
import * as Unicons from '@iconscout/react-unicons';
import DownloadGuideHero from '../../../assets/images/hcp-landing/download-guide-hero.svg';
import { useFirstTimeVisible } from '../../../components/shared/hooks/useFirstTimeVisible';

const DownloadGuide = ({ onPressDownload, onSectionIsVisible }) => {
  const ref = useRef();
  const isVisible = useFirstTimeVisible(ref, onSectionIsVisible);

  return (
    <div className="download-guide">
      <div className="download-guide-hero">
        <img src={DownloadGuideHero} alt="" />
      </div>
      <div className="download-guide-info">
        <h2>
          <span>Download</span> the EczemaWise in Practice guide
        </h2>
        <p>
          A <a>how-to guide</a> to integrating EczemaWise into patient care,
          including tips on how to ask patients to track symptoms and tips on
          including EczemaWise in follow-up communications.
        </p>
        <button onClick={onPressDownload} ref={ref}>
          <Unicons.UilArrowRight size="16" />
          Download now
        </button>
      </div>
    </div>
  );
};

export default DownloadGuide;
