function ExplainerVideo() {
  return (
    <section className="home-page__video-section">
      <div className="content-wrapper">
        <div className="video-section">
          <iframe
            className="video-iframe"
            src="https://player.vimeo.com/video/801334110?h=bd6f122f3f&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen
            title="EczemaWise-v2.MP4"></iframe>
          <script src="https://player.vimeo.com/api/player.js"></script>
        </div>
        <div className="content-section">
          <h2 className="title__homepage-large title__primary100">
            It’s not just your
            <span className="text__secondary100"> skin</span>
            ...
          </h2>
          <p className="paragraph">
            Eczema affects every aspect of your overall well-being, and figuring
            out how to manage it can become a puzzle. With customizable eczema
            trackers, appointment management tools and access to the latest
            eczema insights, EczemaWise is the app that helps you piece it all
            together.
          </p>
        </div>
      </div>
    </section>
  );
}

export default ExplainerVideo;
