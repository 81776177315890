import * as Unicons from '@iconscout/react-unicons';

const ProviderOption = ({
  title,
  subtitle,
  checked,
  onSelect,
  default_address,
  onEditPress,
}) => {
  return (
    <div className={`provider-option ${checked ? 'checked' : ''}`}>
      <div className="provider-option__content" onClick={onSelect}>
        <div className="provider-option__circle"></div>
        <span className="provider-option__title">
          {title} {default_address && '(default)'}
        </span>
        <span className="provider-option__subtitle">{subtitle}</span>
      </div>
      <div className="provider-option__edit" onClick={onEditPress}>
        <Unicons.UilEditAlt size={16} color="#1D62ED" />
      </div>
    </div>
  );
};

export const ProviderAddButton = ({ title, onAddPress }) => {
  return (
    <div className="provider-add-button" onClick={onAddPress}>
      <div className="provider-add-button__icon">
        <Unicons.UilPlus size="16" color="white" />
      </div>
      <div className="provider-add-button__text">{title}</div>
    </div>
  );
};

export default ProviderOption;
