import About from './containers/About';
import AdminContainer from './containers/Admin';
import Dashboard from './containers/Dashboard';
import Error from './containers/Error';
import ForgotPassword from './containers/ForgotPassword';
import Help from './containers/Help';
import Home from './containers/Home';
import Login from './containers/Login';
import NotFound from './containers/NotFound';
import Onboarding from './containers/Onboarding';
import PrivacyPolicy from './containers/PrivacyPolicy';
import ProviderPrep from './containers/ProviderPrep';
import ResetPassword from './containers/ResetPassword';
import Resources from './containers/Resources';
import Settings from './containers/Settings';
import Surveys from './containers/Surveys';
import Trends from './containers/Trends';
import UserRegistration from './containers/UserRegistration';
import Gallery from './containers/Gallery';
import { Landing as HCPLanding } from './containers/HCP';

const routes = [
  {
    path: '/',
    name: 'Home',
    exact: true,
    component: Home,
  },
  {
    path: '/resources/:resourceType/:topicId?/:topicName?',
    name: 'Resources',
    exact: true,
    component: Resources,
  },
  {
    path: '/login',
    name: 'Login',
    exact: true,
    component: Login,
  },
  {
    path: '/admin',
    name: 'Admin',
    exact: false,
    auth: true,
    component: AdminContainer,
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    exact: true,
    auth: true,
    component: Dashboard,
  },
  {
    path: '/trends',
    name: 'Trends',
    exact: true,
    auth: true,
    component: Trends,
  },
  {
    path: '/provider-prep',
    name: 'ProviderPrep',
    auth: true,
    component: ProviderPrep,
  },
  {
    path: '/error',
    name: 'Error',
    exact: true,
    component: Error,
  },
  {
    path: '/signup',
    name: 'UserRegistration',
    component: UserRegistration,
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    exact: true,
    component: ForgotPassword,
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    exact: true,
    component: ResetPassword,
  },
  {
    path: '/help',
    name: 'Help',
    exact: true,
    component: Help,
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    exact: true,
    component: PrivacyPolicy,
  },
  {
    path: '/settings',
    name: 'Settings',
    exact: true,
    auth: true,
    component: Settings,
  },
  {
    path: '/gallery',
    name: 'Gallery',
    exact: true,
    auth: true,
    component: Gallery,
  },
  {
    path: '/surveys',
    name: 'Surveys',
    exact: false,
    auth: true,
    component: Surveys,
  },
  {
    path: '/about',
    name: 'About',
    exact: true,
    component: About,
  },
  {
    path: '/onboarding',
    name: 'Onboarding',
    exact: true,
    component: Onboarding,
  },
  {
    path: '/for-healthcare-providers',
    name: 'HCPLanding',
    exact: true,
    component: HCPLanding,
  },
  {
    path: '*',
    name: 'NotFound',
    exact: true,
    component: NotFound,
  },
];

export default routes;
