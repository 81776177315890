import React, { useCallback } from 'react';

// LIBS
import * as Unicons from '@iconscout/react-unicons';
import moment from 'moment-timezone';
import ReactTooltip from 'react-tooltip';

// UTILS
import { POSCORAD_TOOLTIP, PRICING_SCALE_TOOLTIP } from '../../utils/constants';
import analytics from '../../libs/analytics';
import MixpanelService, { MixpanelEvents } from '../../libs/mixpanel';

// COMPONENTS
import { GalleryImage } from './components';
import PoscoradHistory from '../../components/providerprep/PoscoradHistory';
import { HelperIcon } from '../../assets/icons';

// REDUX
import { useAppSelector } from '../../store';

// APOLLO
import { useQuery } from 'react-apollo';
import { UserPoScoradHistory } from '../../libs/graphql/queries/user';
import { TreatmentOptionsAndMetadata } from '../../libs/graphql/queries/data';

const PrepSummary = ({ me }) => {
  // REDUX
  const appointmentPrep = useAppSelector(state => state.appointmentPrep);
  const metadata = useAppSelector(state => state.appointmentPrep.metadata);

  // QUERIES
  const { data: treatmentOptionsAndMetadata } = useQuery(
    TreatmentOptionsAndMetadata,
  );

  const getTreatmentMetadata = useCallback(
    treatmentId => {
      if (!treatmentOptionsAndMetadata?.availableTreatmentOptions) {
        return {};
      }

      const { availableTreatmentOptions, availableTreatmentOptionsMetadata } =
        treatmentOptionsAndMetadata;

      const treatment = availableTreatmentOptions.find(
        t => t.id === treatmentId,
      );
      if (!treatment) {
        return {};
      }

      const metadata = availableTreatmentOptionsMetadata.find(
        m => m.id === treatment.metadata_id,
      );
      return metadata || {};
    },
    [treatmentOptionsAndMetadata],
  );

  const getTreatmentCost = treatmentId => {
    const metadata = getTreatmentMetadata(treatmentId);
    const cost = metadata?.cost;

    if (!cost) {
      return '0';
    }
    return Array(cost).fill('$').join('');
  };

  const getTreatmentName = treatmentId => {
    const { selectedTreatmentsIds, selectedTreatmentsNames } = appointmentPrep;
    const indexOf = selectedTreatmentsIds.findIndex(id => id === treatmentId);

    if (selectedTreatmentsNames[indexOf]) {
      return selectedTreatmentsNames[indexOf];
    }
    return '';
  };

  // POSCORAD
  const startDate = moment(appointmentPrep.date).subtract(90, 'd');
  const endDate = moment(appointmentPrep.date);
  const poscoradStartDate = startDate.format('YYYY-MM-DD');
  const poscoradEndDate = endDate.format('YYYY-MM-DD');
  const userid = me.id;

  const { data: poscoradData } = useQuery(UserPoScoradHistory, {
    variables: {
      payload: {
        userid,
        startDate: poscoradStartDate,
        endDate: poscoradEndDate,
      },
    },
  });

  const poscoradHistory = poscoradData?.getPoScoradHistory || null;

  return (
    <div className="appointmentPrepStep">
      <ReactTooltip
        type="light"
        multiline={true}
        className={'help-tooltip-right'}
        place="right"
        html={true}
        clickable
        offset={{ top: -50, left: -10 }}
      />
      {/* FIND FOCUS */}
      <div className="card overview">
        <div className="card-header bordered">
          <h3>Find focus</h3>
        </div>
        <div className="card-content">
          <label>At my appointment, I want to:</label>
          <ol>
            {appointmentPrep.issues.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ol>

          <label>
            What is important to you when considering an eczema treatment?{' '}
          </label>
          <ol>
            {appointmentPrep.priorities.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ol>

          <label>Notes</label>
          <p>{appointmentPrep.notes || 'N/A'}</p>
        </div>
      </div>

      {/* THINK BIG PICTURE */}
      <div className="card overview">
        <div className="card-header bordered">
          <h3>Think Big Picture</h3>
        </div>
        <div className="card-content">
          <label>During the last week, how often have you been:</label>
          <div className="indications">
            <strong>0 =</strong>
            <span>Never ,</span>
            <strong>6 =</strong>
            <span>Always</span>
          </div>
          <div className="overview-results">
            <div className="overview-result">
              <div className="result-number">
                <span>{appointmentPrep.picture_self}</span>
              </div>
              <p>
                Bothered by symptoms of your skin problem. (e.g., itching,
                stinging, burning, hurting or skin irritation)
              </p>
            </div>
            <div className="overview-result">
              <div className="result-number">
                <span>{appointmentPrep.picture_social}</span>
              </div>
              <p>
                Emotionally bothered by your skin problem. (e.g., worry,
                embarrassment, frustration)
              </p>
            </div>
            <div className="overview-result">
              <div className="result-number">
                <span>{appointmentPrep.picture_work}</span>
              </div>
              <p>
                Bothered by effects of your skin problem on your ACTIVITIES.
                (e.g., going out, accomplishing what your want, work activities,
                or your relationships with others)
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* TREATMENT OPTIONS */}
      <div className="card overview">
        <div className="card-header bordered">
          <h3>Treatment options</h3>
        </div>
        <div className="card-content">
          <p>
            These are the treatment options I would like to discuss with my
            provider at this appointment.
          </p>
          <div className="overview-results">
            {appointmentPrep.selectedTreatmentsIds.map(treatmentId => (
              <div className="overview-result" key={treatmentId}>
                <div className="price-badge">
                  {getTreatmentCost(treatmentId)}
                </div>
                <div className="help-button" data-tip={PRICING_SCALE_TOOLTIP}>
                  <HelperIcon />
                </div>
                <p>{getTreatmentName(treatmentId)}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* PHOTOS */}
      <div className="card overview">
        <div className="card-header bordered">
          <h3>Photos</h3>
        </div>
        <div className="card-content">
          <p>
            These are the photos I would like to discuss with my provider at
            this appointment.
          </p>
          <div className="galleryImages readOnly">
            {appointmentPrep.selectedImages.map((image, index) => (
              <GalleryImage
                key={image.imageid}
                imageId={image.imageid}
                favorite={image.favorite}
                area_affected={image.area_affected}
                tracker_date={image.tracker_date}
                readOnly
              />
            ))}
          </div>
        </div>
        {metadata && (
          <div className="card-footer">
            <button className="link-button">View all photos</button>
            <a
              href={`/api/appointment/${appointmentPrep?.metadata?.appointment_id}.pdf`}
              onClick={e => {
                analytics().logEvent('manage_download_pdf', {});
                MixpanelService.track(MixpanelEvents.DownloadSummaryTapped, {
                  AppointmentID: appointmentPrep?.metadata?.appointment_id,
                });
              }}
              download={true}>
              <button className="link-button">
                <Unicons.UilDownloadAlt size="20" />
                <span>Download</span>
              </button>
            </a>
          </div>
        )}
      </div>

      {/* PO-SCORAD */}
      <div className="card overview">
        <div className="card-header bordered header-with-tooltip">
          <h3>PO-SCORAD</h3>
          <div className="help-button" data-tip={POSCORAD_TOOLTIP}>
            <HelperIcon />
          </div>
        </div>
        <div className="card-content">
          <p>
            PO-SCORAD is a measure of eczema severity and is generated when you
            complete the skin, itch, and sleep trackers in the same day
          </p>
          {poscoradHistory && (
            <PoscoradHistory
              history={poscoradHistory?.History}
              averageLevel={poscoradHistory?.averageLevel}></PoscoradHistory>
          )}
        </div>
      </div>

      {/* HISTORICAL TRENDS DATA */}
      <div className="card overview">
        <div className="card-header bordered">
          <h3>Historical Trends Data</h3>
        </div>
        <div className="card-content">
          <p>
            {`${startDate.format('MMM D, YYYY')} to ${endDate.format(
              'MMM D, YYYY',
            )}`}
          </p>
          <img
            width={900}
            height={600}
            src={`/api/charts/trends?startDate=${startDate.format(
              'YYYY-MM-DD',
            )}&endDate=${endDate.format('YYYY-MM-DD')}`}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default PrepSummary;
