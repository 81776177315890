// QUERIES
import { createAsyncThunk } from '@reduxjs/toolkit';
import { UserAppointmentsMetadata } from '../../libs/graphql/queries/user';
import Client from '../../apollo/client';

export const fetchAppointmentMetadata = createAsyncThunk(
  'appointmentPrep/fetchAppointmentMetadata',
  async appointmentId => {
    try {
      const { data } = await Client.query({
        query: UserAppointmentsMetadata,
        variables: { appointmentId },
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      });

      return data.getAppointmentMetadata;
    } catch (error) {
      console.log('ERROR', error);
      throw new Error('Failed to fetch appointments');
    }
  },
);
