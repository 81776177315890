import React from 'react';

const FormItem = props => {

  const handleValueChange = (e) => {
    if (props.onChange) {
      props.onChange(e.target.value);
    }
  };

  return (
    <div className="form-item">
      <label className="form-input-label-settings text__left">
        {props.label ?? 'Password'}
        {props.required ?? <span className="red-asterisk">*</span>}
      </label>
        <input
          className="form-input-field"
          type={props.type ?? 'text'}
          value={props.value}
          onChange={handleValueChange}
          placeholder={props.placeholder ?? ''}
        />
    </div>
  );
};

export default FormItem;
