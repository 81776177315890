import React, { memo } from 'react';

const Multiselect = ({ options, selectedOptions, maxItems = 0, onChange }) => {
  const toggleOption = option => {
    if (maxItems === 1) {
      onChange([option]);
      return;
    }

    if (selectedOptions.includes(option)) {
      onChange(selectedOptions.filter(item => item !== option));
      return;
    }

    if (!maxItems || selectedOptions.length < maxItems) {
      onChange([...selectedOptions, option]);
    }
  };

  return (
    <div className="multiSelect">
      {options.map(option => (
        <button
          key={option}
          className={`multiSelect__button ${
            selectedOptions.includes(option) ? 'selected' : ''
          }`}
          onClick={() => toggleOption(option)}>
          {option}
        </button>
      ))}
    </div>
  );
};

export default memo(Multiselect);
