import React, { useRef } from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import WhoDevelopedHero from '../../../assets/images/hcp-landing/who-developed-hero.svg';
import { useFirstTimeVisible } from '../../../components/shared/hooks/useFirstTimeVisible';

const WhoDeveloped = ({ onSectionIsVisible }) => {
  const ref = useRef();
  const isVisible = useFirstTimeVisible(ref, onSectionIsVisible);

  return (
    <div className="how-to-use developed">
      <div className="how-to-use-info">
        <h2>
          Who <span>developed/owns</span> the app?
        </h2>
        <p>
          EczemaWise is a trademark of the National Eczema Association (NEA),
          who owns and operates the app. We gratefully acknowledge the
          physicians, patients and others who supported the initial development
          of EczemaWise.
        </p>
        <p ref={ref}>
          We meet with NEA’s Scientific and Medical Advisory Council members and
          Patient and Caregiver Research Advisory Council members on an ongoing
          basis for stakeholder input.{' '}
          <Link to="/about" className="help-link">
            Read more.
          </Link>
        </p>
      </div>
      <div className="how-to-use-hero">
        <img src={WhoDevelopedHero} alt="" />
      </div>
    </div>
  );
};

export default WhoDeveloped;
