import React, { memo } from 'react';

// COMPONENTS
import { Multiselect } from './components';

// REDUX
import { useAppDispatch, useAppSelector } from '../../store';
import { appointmentPrepActions } from '../../store/appointmentPrep';

// OPTIONS
const issuesOptions = [
  'Talk about how to manage my symptoms',
  'Get medical tests',
  'Talk about a new health problem',
  'Get or change my medication or treatment',
  'Discuss new treatment options',
  'Other',
];
const prioritiesOptions = [
  'Avoiding the risk of serious side effects',
  'Cost',
  'Getting quick results',
  'Convenience',
  'How well it works',
  'How much time it takes to do treatment',
];

const PrepStep1 = () => {
  const dispatch = useAppDispatch();
  const issues = useAppSelector(state => state.appointmentPrep.issues);
  const priorities = useAppSelector(state => state.appointmentPrep.priorities);

  const onChangeIssues = items => {
    dispatch(appointmentPrepActions.setIssues(items));
  };

  const onChangePrioritiesItems = items => {
    dispatch(appointmentPrepActions.setPriorities(items));
  };

  return (
    <div className="appointmentPrepStep">
      <div className="card">
        <div className="card-header">
          <h3>1. At my appointment, I want to: *</h3>
        </div>
        <p>Select all that apply</p>
        <Multiselect
          options={issuesOptions}
          selectedOptions={issues}
          onChange={onChangeIssues}
        />
      </div>
      <div className="card">
        <div className="card-header">
          <h3>
            2. What is important to you when considering an eczema treatment? *
          </h3>
        </div>
        <p>Select at least 1 important aspect (up to 3)</p>
        <Multiselect
          options={prioritiesOptions}
          selectedOptions={priorities}
          onChange={onChangePrioritiesItems}
          maxItems={3}
        />
      </div>
    </div>
  );
};

export default memo(PrepStep1);
