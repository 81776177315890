import React from 'react';
import { Link } from 'react-router-dom';
import analytics from '../libs/analytics';
import MixpanelService from '../libs/mixpanel';
import LogoInverse from '../assets/images/EczemaWise-logo-inverse.png';
import eHealthcareAward from '../assets/images/eHealthcare-award.png';
import AppStore from '../assets/images/home/app-store.svg';
import GooglePlay from '../assets/images/home/google-play.svg';
import SocialFacebook from '../assets/icons/social-facebook.svg';
import SocialInstagram from '../assets/icons/social-instagram.svg';
import SocialTwitter from '../assets/icons/social-twitter.svg';
import SocialLinkedin from '../assets/icons/social-linkedin.svg';

import FooterNEALogo from '../assets/images/newBrandImages/FooterNEALogo.png'
import eHealthcareAward22 from '../assets/images/newBrandImages/eHealthcareAward22.png';
import eHealthcareAward23 from '../assets/images/newBrandImages/eHealthcareAward23.png';
import LogoWinnerBadge from '../assets/images/newBrandImages/LogoWinnerBadge.png';


const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-logo-container">
          <Link to="/" className="title link__white footer-link" title="Home">
            <img src={FooterNEALogo} alt="logo" />
          </Link>
        </div>

        <div className="footer-awards-container">
          <div className="footer-award-container">
            <img src={LogoWinnerBadge} alt="" />
          </div>
          <div className="footer-award-container">
            <img src={eHealthcareAward22} alt="" />
          </div>
          <div className="footer-award-container">
            <img src={eHealthcareAward23} alt="" />
          </div>
        </div>
        <div className="footer-links-container">
          {
            <ul className="footer-menu footer-menu-list footer-menu-list-1">
              <li>
                <Link
                  to="/about"
                  className="paragraph link__white footer-link"
                  onClick={() => MixpanelService.track('CreditsTapped')}>
                  About EczemaWise
                </Link>
              </li>
              <li>
                <Link
                  to="/privacy-policy"
                  className="paragraph link__white footer-link">
                  Privacy Policy
                </Link>
              </li>

              <li>
                <Link
                  to="/help"
                  className="paragraph link__white footer-link"
                  onClick={() => MixpanelService.track('HelpTapped')}>
                  Help & FAQ
                </Link>
              </li>
            </ul>
          }
          {/* <ul className="footer-menu footer-menu-list footer-menu-list-2" />*/}

          {/* you'll need to change the class footer-menu-list-1 to reflect the correct column number*/}
          <ul className="footer-menu footer-menu-list footer-menu-list-2">
            <li>
              <a
                href="https://nationaleczema.org/"
                target="_blank"
                className="paragraph link__white footer-link"
                rel="noreferrer">
                National Eczema Association
              </a>
            </li>
            <li>
              <a
                href="https://nationaleczema.org/about-nea/"
                target="_blank"
                className="paragraph link__white footer-link"
                rel="noreferrer">
                About Us
              </a>
            </li>
            <li>
              <a
                href="https://www.tfaforms.com/4848315"
                target="_blank"
                className="paragraph link__white footer-link"
                rel="noreferrer">
                Contact
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="footer-stores-container">
        <a
          className="store-button"
          onClick={e => {
            analytics().logEvent('ios_dl_page_from_home', {});
          }}
          href="https://apps.apple.com/us/app/id1522743215"
          target="_blank"
          rel="noreferrer">
          <img src={AppStore} alt="Download on the Apple Store" />
        </a>
        <a
          className="store-button"
          onClick={e => {
            analytics().logEvent('android_dl_page_from_home', {});
          }}
          href="https://play.google.com/store/apps/details?id=com.eczemawiseapp"
          target="_blank"
          rel="noreferrer">
          <img src={GooglePlay} alt="Get it on Google Play" />
        </a>
      </div>
      <div className="footer-copyright">
        <p className="paragraph paragraph__white">
          {/* &copy; is the copyright symbol for html :) */}
          &copy; 2018-{new Date().getFullYear()} National Eczema Association.
          All Rights Reserved.
        </p>
        <div className="footer-social-links">
          <a
            href="https://www.facebook.com/nationaleczema"
            className="social-link"
            target="_blank"
            rel="noreferrer"
            title="Facebook">
            <img src={SocialFacebook} alt="National Eczema on Facebook" />
          </a>

          <a
            href="https://www.instagram.com/eczemawise/"
            className="social-link"
            target="_blank"
            rel="noreferrer"
            title="Instagram">
            <img src={SocialInstagram} alt="National Eczema on Instagram" />
          </a>

          <a
            href="https://twitter.com/nationaleczema"
            className="social-link"
            target="_blank"
            rel="noreferrer"
            title="Twitter">
            <img src={SocialTwitter} alt="National Eczema on Twitter" />
          </a>

          <a
            href="https://www.linkedin.com/company/national-eczema-association"
            target="_blank"
            rel="noreferrer"
            className="social-link"
            title="LinkedIn">
            <img src={SocialLinkedin} alt="National Eczema on LinkedIn" />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
