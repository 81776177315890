import React, { memo } from 'react';
import * as Unicons from '@iconscout/react-unicons';
import moment from 'moment-timezone';

// COMPONENTS
import { BodyAreas } from '../../../utils/constants';

const getBodyAreaLabel = affected_area => {
  const bodyArea = BodyAreas.find(bodyArea => bodyArea.value === affected_area);
  return bodyArea ? bodyArea.label : '';
};

const getFormattedDate = date => {
  return moment(date, 'YYYY-MM-DDTHH:mm:ss[Z]').format('DD/MM/YYYY');
};

const GalleryImage = ({
  imageId,
  favorite,
  area_affected,
  tracker_date,
  readOnly = false,
  checked = false,
  onClick,
}) => {
  return (
    <div
      className={`galleryItem ${favorite ? 'favorite' : ''} ${
        checked ? 'checked' : ''
      }  ${readOnly ? 'readOnly' : ''}`}
      onClick={onClick}
      key={imageId}>
      <div
        className="galleryImage"
        style={{
          backgroundImage: `url(/img/${imageId})`,
        }}>
        {!readOnly && (
          <div className="check">
            <Unicons.UilCheck size="16" />
          </div>
        )}
        <div className="favorite">
          <Unicons.UilStar size="20" />
        </div>
      </div>
      <span className="summary">{getBodyAreaLabel(area_affected)}</span>
      {readOnly && (
        <span className="date">{getFormattedDate(tracker_date)}</span>
      )}
    </div>
  );
};

export default memo(GalleryImage);
