import React, { useState, useEffect } from 'react';

// LIBS
import * as Unicons from '@iconscout/react-unicons';
import moment from 'moment-timezone';
import Dropdown from 'react-dropdown';
import { useQuery } from 'react-apollo';
import ReactTooltip from 'react-tooltip';

// COMPONENTS
import { SkinBodyFront, SkinBodyBack } from '../../assets/images';
import UploadImage from '../../components/gallery/uploadImage';
import GalleryIcon from '../../assets/icons/gallery-icon.svg';

// REDUX
import { useAppDispatch, useAppSelector } from '../../store';
import { appointmentPrepActions } from '../../store/appointmentPrep';

// APOLLO
import { galleryImages } from '../../libs/graphql/queries/image';
import { GalleryImage } from './components';
import FiltersList from '../../components/gallery/FiltersList';
import { HelperIcon } from '../../assets/icons';

// OPTIONS
const MAX_IMAGES = 30;
const dateRanges = [
  'Last 2 weeks',
  'Last month',
  'Last 3 months',
  'Last year',
  'Today',
  'All',
  'Custom',
];
const ECZEMA_GALLERY_TOOLTIP = `
  <h4>What is the eczema gallery?</h4>
  <p>Select photos that represent what your eczema has looked like since your last healthcare provider visit, including your best days and worst days. Having photos on hand can help support conversations about your care and treatment decisions.</p>
`;

const getDateFrom = selectedDateRange => {
  switch (selectedDateRange) {
    case 'Last 2 weeks':
      return moment().subtract(2, 'weeks');
    case 'Last month':
      return moment().subtract(1, 'months');
    case 'Last 3 months':
      return moment().subtract(3, 'months');
    case 'Last year':
      return moment().subtract(1, 'years');
    case 'Today':
      return moment();
    case 'All':
      return moment().subtract(100, 'years');
    default:
      return moment().subtract(1, 'weeks');
  }
};

const getSeverity = level => {
  if (!level) {
    return 'No PO-SCORAD';
  }
  if (level < 33) {
    return 'Mild';
  } else if (level < 66) {
    return 'Moderate';
  } else {
    return 'Severe';
  }
};

const getSeverityColor = level => {
  if (!level) {
    return '#dedede';
  }
  if (level < 33) {
    return '#FF9672';
  } else if (level < 66) {
    return '#E95D42';
  } else {
    return '#B33D26';
  }
};

const PrepStep4 = ({ me }) => {
  // REDUX
  const dispatch = useAppDispatch();
  const selectedImagesIds = useAppSelector(
    state => state.appointmentPrep.selectedImagesIds,
  );

  // LOCAL STATE
  const [showGalleryFilters, setShowGalleryFilters] = useState(false);
  const [filterSort, setFilterSort] = useState('Newest to oldest');
  const [filterSeverities, setFilterSeverities] = useState([]);
  const [filterFavorites, setFilterFavorites] = useState(false);
  const [areasAffected, setAreasAffected] = useState([]);
  const [selectedDateRange, setSelectedDateRange] = useState('Last 2 weeks');
  const [dateFrom, setDateFrom] = useState(moment().subtract(2, 'weeks'));
  const [dateTo, setDateTo] = useState(moment());
  const [trackerDates, setTrackerDates] = useState([]);
  const [showUploadImage, setShowUploadImage] = useState(false);

  const {
    data: eczemaGallery,
    refetch: refetchEczemaGallery,
    loading: loadingGallery,
  } = useQuery(galleryImages, {
    variables: {
      userid: me.id,
      date_from: dateFrom.format('YYYY-MM-DD'),
      date_to: dateTo.format('YYYY-MM-DD'),
    },
  });

  const getTrackerDates = images => {
    const trackerDates = [];

    images.forEach(image => {
      let skip = false;
      if (filterFavorites && !image.favorite) {
        skip = true;
      }
      if (areasAffected.length > 0) {
        if (!areasAffected.includes(image.area_affected)) {
          skip = true;
        }
      }

      if (!skip) {
        const trackerDate = trackerDates.find(
          trackerDate => trackerDate.tracker_date === image.tracker_date,
        );
        if (trackerDate) {
          trackerDate.images.push(image);
        } else {
          trackerDates.push({
            tracker_date: image.tracker_date,
            title: moment(image.tracker_date, 'YYYY-MM-DDTHH:mm:ss[Z]').format(
              'MMMM DD, YYYY',
            ),
            level: image.level,
            severity: getSeverity(image.level),
            color: getSeverityColor(image.level),
            images: [image],
          });
        }
      }
    });

    setTrackerDates(trackerDates);
  };

  // Refetch eczema gallery when the date range changes
  useEffect(() => {
    refetchEczemaGallery();
  }, [selectedDateRange, dateFrom]);

  // Process eczema gallery data
  useEffect(() => {
    if (eczemaGallery?.getEczemaGalleryImages) {
      getTrackerDates(eczemaGallery.getEczemaGalleryImages);
    }
  }, [eczemaGallery]);

  // When filterFavorites changes, update gallery
  useEffect(() => {
    if (eczemaGallery?.getEczemaGalleryImages) {
      getTrackerDates(eczemaGallery.getEczemaGalleryImages);
    }
  }, [filterFavorites]);

  // When areasAffected changes, update gallery
  useEffect(() => {
    if (eczemaGallery?.getEczemaGalleryImages) {
      getTrackerDates(eczemaGallery.getEczemaGalleryImages);
    }
  }, [areasAffected]);

  const toggleFilterSeverity = severity => {
    const newFilterSeverities = [...filterSeverities];
    if (newFilterSeverities.includes(severity)) {
      newFilterSeverities.splice(newFilterSeverities.indexOf(severity), 1);
    } else {
      newFilterSeverities.push(severity);
    }
    setFilterSeverities(newFilterSeverities);
  };

  const handleAreasAffected = area => {
    if (area?.target?.id) {
      if (areasAffected.includes(area.target.id)) {
        const index = areasAffected.indexOf(area.target.id);
        const newAreasAffected = [...areasAffected];
        newAreasAffected.splice(index, 1);
        setAreasAffected(newAreasAffected);
      } else {
        setAreasAffected([...areasAffected, area.target.id]);
      }
    }
  };

  const resetFilters = () => {
    setFilterSort('Newest to oldest');
    setFilterSeverities([]);
    setFilterFavorites(false);
    setAreasAffected([]);
  };

  const toggleImage = image => {
    if (
      !selectedImagesIds.includes(image.imageid) &&
      selectedImagesIds.length >= MAX_IMAGES
    ) {
      return;
    }
    dispatch(appointmentPrepActions.toggleImage(image));
  };

  const handleDateRangeChange = dateRange => {
    setSelectedDateRange(dateRange.value);
    const dateFrom = getDateFrom(dateRange.value);
    setDateFrom(dateFrom);
  };

  const deselectAllImages = () => {
    dispatch(appointmentPrepActions.resetImages());
  };

  const getFilteredTrackerDates = () => {
    // Filter by severity
    const mildSelected = filterSeverities.includes('Mild');
    const moderateSelected = filterSeverities.includes('Moderate');
    const severeSelected = filterSeverities.includes('Severe');
    const filteredTrackerDates = trackerDates.filter(trackerDate => {
      if (!mildSelected && !moderateSelected && !severeSelected) {
        return true;
      }
      return filterSeverities.includes(trackerDate.severity);
    });

    // Sort by date / severity
    if (filterSort.includes('Oldest to newest')) {
      return filteredTrackerDates.sort((a, b) => {
        return moment(a.tracker_date).diff(moment(b.tracker_date));
      });
    }
    if (filterSort.includes('Higher to lower PO-SCORAD')) {
      return filteredTrackerDates.sort((a, b) => {
        return b.level - a.level;
      });
    }
    if (filterSort.includes('Lower to higher PO-SCORAD')) {
      return filteredTrackerDates.sort((a, b) => {
        return a.level - b.level;
      });
    }
    return filteredTrackerDates.sort((a, b) => {
      return moment(b.tracker_date).diff(moment(a.tracker_date));
    });
  };

  const filteredTrackerDates = getFilteredTrackerDates();

  const filtersApplied =
    (filterSort !== 'Newest to oldest' ? 1 : 0) +
    filterSeverities.length +
    (filterFavorites ? 1 : 0) +
    areasAffected.length;

  const severityFilters = {
    Mild: filterSeverities.includes('Mild'),
    Moderate: filterSeverities.includes('Moderate'),
    Severe: filterSeverities.includes('Severe'),
  };

  return (
    <div className="appointmentPrepStep">
      <ReactTooltip
        type="light"
        multiline={true}
        className={'help-tooltip-right'}
        place="right"
        html={true}
        clickable
        offset={{ top: -50, left: -10 }}
      />
      <div className="card">
        <p>Select your most relevant photos (up to 30):</p>
      </div>

      <div className="card card-filters">
        <div className="card-header card-header-filters">
          <div className="card-header-title">
            <h4>My Eczema Gallery</h4>
            <div className="help-button" data-tip={ECZEMA_GALLERY_TOOLTIP}>
              <HelperIcon />
            </div>
          </div>
          <div className="card-header-buttons">
            <Dropdown
              className="gallery-day-picker"
              classNamePrefix="form-select"
              options={dateRanges}
              onChange={handleDateRangeChange}
              value={selectedDateRange}
              style={{ width: '120px' }}
            />
            <div
              className="galleryFilterButton"
              onClick={() => {
                setShowGalleryFilters(true);
              }}>
              <Unicons.UilFilter size="20" />
              {filtersApplied > 0 && (
                <div className="filterCount">{filtersApplied}</div>
              )}
            </div>
            <button
              className="button button__secondary100-inverted button__btn-14-600 button-rectangle-skin-footer button-row-space buttonUploadPhoto"
              onClick={e => {
                e.preventDefault();
                setShowUploadImage(true);
              }}>
              Upload photo
            </button>
          </div>
        </div>
        {filtersApplied > 0 && (
          <div className="card-content">
            <FiltersList
              severityFilters={severityFilters}
              toggleSeverityFilter={toggleFilterSeverity}
              favoriteFilter={filterFavorites}
              toggleFavoriteFilter={() => setFilterFavorites(!filterFavorites)}
              bodyPartFilter={areasAffected}
              toggleBodyPartFilter={handleAreasAffected}
              resetAll={() => resetFilters()}
            />
          </div>
        )}
      </div>

      <div className="card">
        <div className="card-header card-header-selected-photos">
          <h4>
            Selected photos {selectedImagesIds.length}/{MAX_IMAGES}
          </h4>
          {selectedImagesIds.length > 0 && (
            <button
              onClick={e => {
                e.preventDefault();
                deselectAllImages();
              }}
              className="link-button">
              Deselect All
            </button>
          )}
        </div>
      </div>

      <div className="card card-images">
        {filteredTrackerDates.map(trackerDate => (
          <div className="galleryBlock" key={trackerDate.tracker_date}>
            <div className="galleryTitle">
              <strong>{trackerDate.title} -</strong>
              <div
                className="intensityDot mild"
                style={{ backgroundColor: trackerDate.color }}></div>
              <strong>
                {trackerDate.level} - {trackerDate.severity}
              </strong>
            </div>
            <div className="galleryImages">
              {trackerDate.images.map((image, index) => (
                <GalleryImage
                  key={image.imageid}
                  imageId={image.imageid}
                  favorite={image.favorite}
                  checked={selectedImagesIds.includes(image.imageid)}
                  area_affected={image.area_affected}
                  tracker_date={trackerDate.tracker_date}
                  onClick={() => toggleImage(image)}
                />
              ))}
            </div>
          </div>
        ))}

        {!loadingGallery && filteredTrackerDates.length === 0 && (
          <div className="noEczemaGallery">
            <div className="icon">
              <img src={GalleryIcon} alt="No selected" />
            </div>
            <h3>You don’t have any images saved to this body part</h3>
          </div>
        )}
      </div>

      <UploadImage
        me={me}
        isModalOpen={showUploadImage}
        onClose={() => setShowUploadImage(false)}
        onUpload={() => {
          refetchEczemaGallery();
          setShowUploadImage(!showUploadImage);
        }}
      />

      {showGalleryFilters && (
        <div className="filtersOverlay">
          <div className="filtersForm">
            <div className="filtersTitle">
              <h3>Filters</h3>
              <div onClick={() => setShowGalleryFilters(false)}>
                <Unicons.UilTimes size="24" />
              </div>
            </div>
            <div className="filtersContent">
              <h4>Sort by</h4>
              <div
                className={`optionButton ${
                  filterSort === 'Newest to oldest' ? 'active' : ''
                }`}
                onClick={() => setFilterSort('Newest to oldest')}>
                <label>Newest to oldest</label>
                <div className="radioBtn"></div>
              </div>
              <div
                className={`optionButton ${
                  filterSort === 'Oldest to newest' ? 'active' : ''
                }`}
                onClick={() => setFilterSort('Oldest to newest')}>
                <label>Oldest to newest</label>
                <div className="radioBtn"></div>
              </div>
              <div
                className={`optionButton ${
                  filterSort === 'Higher to lower PO-SCORAD' ? 'active' : ''
                }`}
                onClick={() => setFilterSort('Higher to lower PO-SCORAD')}>
                <label>Higher to lower PO-SCORAD</label>
                <div className="radioBtn"></div>
              </div>
              <div
                className={`optionButton ${
                  filterSort === 'Lower to higher PO-SCORAD' ? 'active' : ''
                }`}
                onClick={() => setFilterSort('Lower to higher PO-SCORAD')}>
                <label>Lower to higher PO-SCORAD</label>
                <div className="radioBtn"></div>
              </div>
              <div className="separator"></div>
              <h4>PO-SCORAD severity</h4>
              <div
                className={`optionButton ${
                  filterSeverities.includes('Mild') ? 'active' : ''
                }`}
                onClick={() => toggleFilterSeverity('Mild')}>
                <label>Mild</label>
                <div className="checkboxBtn"></div>
              </div>
              <div
                className={`optionButton ${
                  filterSeverities.includes('Moderate') ? 'active' : ''
                }`}
                onClick={() => toggleFilterSeverity('Moderate')}>
                <label>Moderate</label>
                <div className="checkboxBtn"></div>
              </div>
              <div
                className={`optionButton ${
                  filterSeverities.includes('Severe') ? 'active' : ''
                }`}
                onClick={() => toggleFilterSeverity('Severe')}>
                <label>Severe</label>
                <div className="checkboxBtn"></div>
              </div>
              <div className="separator"></div>
              <h4>Favorites</h4>
              <div
                className={`optionButton ${filterFavorites ? 'active' : ''}`}
                onClick={() => setFilterFavorites(!filterFavorites)}>
                <label>Show only Favorites</label>
                <div className="checkboxBtn"></div>
              </div>
              <div className="separator"></div>
              <h4>Body areas</h4>
              <div className="skin-areas-wrapper">
                <div className="skin-areas__single skin-areas__front">
                  <SkinBodyFront
                    areas_affected={areasAffected}
                    onSelect={handleAreasAffected}
                  />
                </div>
                <div className="skin-areas__single skin-areas__front">
                  <SkinBodyBack
                    areas_affected={areasAffected}
                    onSelect={handleAreasAffected}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PrepStep4;
