import React, { useRef, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import {
  DietIcon,
  ItchIcon,
  PainIcon,
  TreatmentIcon,
  TriggersIcon,
} from '../assets/icons/trackers';
import supporters from '../assets/Sponsor Logos and Assets';
import FadeIn from '../components/shared/FadeIn';
import ScrollToTop from '../components/shared/ScrollToTop';
import { useIsVisible } from '../components/shared/hooks/useIsVisible';

//Images
import ECWAbout from '../assets/images/newBrandImages/ECWAbout.svg';
import ECWSkinTracker from '../assets/images/newBrandImages/ECWSkinTracker.svg';
import ECWSkinDex from '../assets/images/newBrandImages/ECWSkinDex.png';
import ECWAdvancingResearch from '../assets/images/newBrandImages/ECWAdvancingResearch.svg';
import ECWTrackApp from '../assets/images/newBrandImages/ECWTrackApp.svg';
import ECWSurveys from '../assets/images/newBrandImages/ECWSurveys.png';
import ECWAdvisors from '../assets/images/newBrandImages/ECWAdvisors.svg';

const About = () => {
  const focusElement = useRef(null);
  const ref = useRef();
  const isVisible = useIsVisible(ref);

  useEffect(() => {
    if (focusElement.current) focusElement.current.focus();
  }, []);

  return (
    <ScrollToTop>
      <FadeIn>
        <div className="main-content content-body web-margin section_bg_white">
          <section className="section-page-about-title content-section content-section__informational div-bottom-margin__8rem">
            <div className="content__row content__space-between">
              <div className="images-wrapper" ref={ref}>
                <img
                  className={`image__about ${isVisible ? 'visible' : ''}`}
                  src={ECWAbout}
                  alt=""
                />
              </div>
              <div className="content-about-wrapper content-about-wrapper__right-section">
                <h1
                  className="about-titles title__secondary100"
                  tabindex="0"
                  ref={focusElement}>
                  About EczemaWise
                </h1>
                <p className="about-paragraph">
                  EczemaWise is an app ({' '}
                  <a
                    className="link link__secondary-100"
                    // onClick={e => {
                    //   analytics().logEvent('ios_dl_page_from_help', {});
                    // }}
                    href="https://apps.apple.com/us/app/id1522743215"
                    target="_blank"
                    rel="noreferrer">
                    Apple App Store
                  </a>{' '}
                  and{' '}
                  <a
                    className="link link__secondary-100"
                    // onClick={e => {
                    //   analytics().logEvent('android_dl_page_from_help', {});
                    // }}
                    href="https://play.google.com/store/apps/details?id=com.eczemawiseapp"
                    target="_blank"
                    rel="noreferrer">
                    Google Play
                  </a>{' '}
                  ) developed by the National Eczema Association (NEA) that
                  helps you make sense of your eczema. While more than 31
                  million people in the United States live with eczema, we know
                  that your experience is unique.
                </p>
                <p className="about-paragraph">
                  Based on science and developed with input from the eczema
                  community and physicians, EczemaWise is an essential tool that
                  tracks your symptoms (like itch and pain) and your triggers
                  (like stress, diet and environment) to reveal trends and
                  insights so you and your doctor can find solutions.
                </p>
                <p className="about-paragraph">
                  In addition to supporting your health, the information you
                  enter — anonymized and combined with the inputs of other
                  EczemaWise users — can lead to critical advances in eczema
                  research. View the EczemaWise Privacy Policy{' '}
                  <NavLink
                    className="link link__secondary-100 link__underline link__bold"
                    target="_blank"
                    to="/privacy-policy">
                    here
                  </NavLink>
                  .
                </p>
              </div>
            </div>
          </section>

          <section className="content-section content-section__informational content__center section_bg_secondary10">
            <div className="content-section__inner content__column">
              <div className="content-text-wrapper">
                <h2 className="about-titles about-titles__center title__secondary100">
                  The science behind EczemaWise
                </h2>
                <p className="about-paragraph about-paragraph__center">
                  EczemaWise includes several trackers built from commonly used
                  medical research and clinical tools that are proven to be
                  reliable measures of particular eczema symptoms. Tracking your
                  information in this way helps you and your healthcare provider
                  “speak the same language” in terms of how eczema impacts your
                  daily life and health. Additional trackers for triggers, diet
                  and treatments supplement your symptom tracking to provide
                  additional areas for insight and discussion.
                </p>
              </div>
            </div>
          </section>

          <section
            id="about-skin-tracker-section"
            className="content-section content-section__informational section_bg_white div-bottom-margin__3rem">
            <div className="content__row content__space-between">
              <div className="content-about-wrapper  content-about-wrapper__left-section">
                <h2 className="about-titles about-titles__left title__secondary100">
                  Skin tracker
                </h2>
                <p className="about-paragraph about-paragraph__left">
                  This tool tracks the severity and location of your eczema over
                  48 hours. Click on the body graphic where your eczema appears.
                  Next, rate the severity of your skin symptoms. In the final
                  screen you can track your skin infections and enter notes.
                </p>
                <p className="about-paragraph about-paragraph__left">
                  The Skin tracker uses the Patient-Oriented SCORing Atopic
                  Dermatitis, or PO-SCORAD, tool. PO-SCORAD was developed as a
                  patient self-assessment tool to determine eczema severity.
                </p>
                <p className="about-paragraph about-paragraph__left">
                  EczemaWise Skin tracker/PO-SCORAD measures the location(s) of
                  eczema on the body as well as common symptoms such as dryness,
                  redness, skin thickness, oozing and swelling rated on scale
                  from 0 = none to 3 = extreme.
                </p>
                <p className="about-paragraph about-paragraph__left">
                  The Skin tracker results can be combined with the results from
                  the Itch and Sleep trackers to determine if eczema is mild,
                  moderate or severe.
                </p>
              </div>
              <div className="images-wrapper" ref={ref}>
                <img
                  className={`image__skin-tracker ${
                    isVisible ? 'visible' : ''
                  }`}
                  src={ECWSkinTracker}
                  alt=""
                />
              </div>
            </div>
          </section>

          <section className="content-section content-section__informational content__center">
            <div className="content-section__inner content__column">
              <div className="content-text-wrapper">
                <div className="profile-list__wrapper-trackers">
                  <div className="profile-item margin-trackers-about">
                    <div className="content__row tracker-theme-skin">
                      <ItchIcon />
                      <h3 className="about-subtitles title__black100">
                        Itch & Sleep Trackers
                      </h3>
                    </div>
                    <p className="about-paragraph about-paragraph__left">
                      These tools track your average itch level and average
                      sleep quality over a 48-hour period. Click on the scale or
                      the forward/back arrows to rate your average level of itch
                      from 0–10.
                    </p>
                    <p className="about-paragraph about-paragraph__left">
                      The EczemaWise Itch and Sleep trackers are part of the
                      PO-SCORAD tool. They are taken from a widely used measure
                      called the Numeric Rating Scale, or NRS. NRS scales are
                      used to measure the severity of many different types of
                      symptoms on a scale of 0 to 10.
                    </p>
                  </div>

                  <div className="profile-item margin-trackers-about">
                    <div className="content__row tracker-theme-skin">
                      <PainIcon />
                      <h3 className="about-subtitles title__black100">
                        Pain & Stress Trackers
                      </h3>
                    </div>
                    <p className="about-paragraph about-paragraph__left">
                      These tools track your average skin pain level and stress
                      level over a 48-hour period. Click on the scale or the
                      forward/back arrows to rate your average level from 0-10.
                    </p>
                    <p className="about-paragraph about-paragraph__left">
                      Both trackers are built using the same NRS scale as the
                      Itch and Sleep Trackers. Research shows that skin pain is
                      a common, but often underreported symptom of eczema, and
                      stress can be a trigger for eczema.
                    </p>
                  </div>

                  <div className="profile-item margin-trackers-about">
                    <div className="content__row tracker-theme-skin">
                      <TreatmentIcon />
                      <h3 className="about-subtitles title__black100">
                        Treatment Tracker
                      </h3>
                    </div>
                    <p className="about-paragraph about-paragraph__left">
                      This tool tracks your daily eczema regimen and allows you
                      to schedule reminders if desired. Click on the treatments
                      you used today (prescription, over-the counter and/or any
                      others) and what time of day you used them (morning,
                      afternoon, evening and/or bedtime).
                    </p>
                    <p className="about-paragraph about-paragraph__left">
                      To update your Treatment tracker as your regimen changes
                      (to add or delete treatments), tap “Edit Treatment List”
                      on mobile, or click on the settings icon at the top of the
                      tracker if using a web browser.
                    </p>
                  </div>

                  <div className="profile-item margin-trackers-about">
                    <div className="content__row tracker-theme-skin">
                      <TriggersIcon />
                      <h3 className="about-subtitles title__black100">
                        Trigger Tracker
                      </h3>
                    </div>
                    <p className="about-paragraph about-paragraph__left">
                      This tool tracks environmental triggers you may have been
                      exposed to each day.
                    </p>
                    <p className="about-paragraph about-paragraph__left">
                      This list includes categories and selections of common
                      potential triggers for eczema, plus a place to add your
                      own. Eczema triggers can be different for everyone, so
                      some of the triggers listed may not apply to you.
                    </p>
                  </div>

                  <div className="profile-item margin-trackers-about">
                    <div className="content__row tracker-theme-skin">
                      <DietIcon />
                      <h3 className="about-subtitles title__black100">
                        Diet Tracker
                      </h3>
                    </div>
                    <p className="about-paragraph about-paragraph__left">
                      Many people with eczema also have food allergies. This
                      tool allows you to track common allergenic foods that you
                      have consumed each day.
                    </p>
                    <p className="about-paragraph about-paragraph__left">
                      You can also track foods that are not on the list. This
                      tool also tracks the number of cups of water you consume,
                      which contributes to healthy skin.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section
            id="shared-decicion-making-section"
            className="content-section content-section__informational content__center section_bg_secondary10">
            <div className="content__row content__space-between">
              <div className="content-about-wrapper  content-about-wrapper__left-section">
                <h2 className="about-titles title__secondary100">
                  The importance of shared decision making and health
                </h2>
                <p className="about-paragraph">
                  Shared decision making is a process by which patients and
                  healthcare providers work together to make joint decisions
                  about care. Participating in shared decision making with your
                  provider(s) can support greater satisfaction with your quality
                  of care and care goals — and potentially lead to better health
                  and quality of life.
                </p>
                <p className="about-paragraph">
                  The Manage tab in EczemaWise is designed to support shared
                  decision making based on best practices from the,{' '}
                  <a
                    className="link link__secondary-100 link__underline link__bold"
                    href="https://www.hhs.gov/"
                    rel="noreferrer"
                    target="_blank">
                    U.S. Department of Health & Human Services
                  </a>{' '}
                  <a
                    className="link link__secondary-100 link__underline link__bold"
                    href="https://www.ahrq.gov/"
                    rel="noreferrer"
                    target="_blank">
                    Agency for Healthcare Research and Quality
                  </a>{' '}
                  <span className="link link__secondary-100">
                    (AHRQ) and the{' '}
                  </span>
                  <a
                    className="link link__secondary-100 link__underline link__bold"
                    href="http://ipdas.ohri.ca/"
                    rel="noreferrer"
                    target="_blank">
                    International Patient decision Aid Standards Collaboration
                  </a>{' '}
                  <span className="link link__secondary-100">(IPDAS).</span>
                </p>
                <p className="about-paragraph">
                  In this section you can set up an appointment card for your
                  next healthcare provider visit, identify items you would like
                  to discuss, highlight treatments of interest and input
                  information on how your eczema has impacted your quality of
                  life in the past week using the Skin-Dex Mini Tool. You can
                  also select photos and generate a graph of your symptom trends
                  over 90 days. The resulting appointment summary can be used to
                  guide and support conversations between you and your
                  healthcare provider(s).
                </p>
              </div>
              <div className="images-wrapper" ref={ref}>
                <img
                  className={`image__skin-dex ${isVisible ? 'visible' : ''}`}
                  src={ECWSkinDex}
                  alt=""
                />
              </div>
            </div>
          </section>

          <section
            id="about-surveys-section"
            className="content-section content-section__informational">
            <div className="content__row content__space-between">
              <div className="images-wrapper" ref={ref}>
                <img
                  className={`image__research ${isVisible ? 'visible' : ''}`}
                  src={ECWAdvancingResearch}
                  alt=""
                />
              </div>
              <div className="content-about-wrapper content-about-wrapper__right-section">
                <h2 className="about-titles about-titles__left title__secondary100">
                  Advancing eczema research
                </h2>
                <p className="about-paragraph about-paragraph__left">
                  The information you — and other app users — enter in
                  EczemaWise forms a research registry. A research registry
                  collects information about people who have a particular
                  medical condition and makes it available to researchers who
                  are studying that condition in an effort to find better
                  treatments and, hopefully one day, a cure.
                </p>
              </div>
            </div>
          </section>

          <section
            id="when-you-tracking"
            className="content-section content-section__informational section_bg_secondary10">
            <div className="content__row content__space-between">
              <div className="images-wrapper" ref={ref}>
                <img
                  className={`image__track-app ${isVisible ? 'visible' : ''}`}
                  src={ECWTrackApp}
                  alt=""
                />
              </div>
              <div className="content-about-wrapper content-about-wrapper__right-section">
                <h2 className="about-titles about-titles__left title__secondary100">
                  When you track in the app, you’re part of finding a cure
                </h2>
                <p className="about-paragraph about-paragraph__left">
                  By using the EczemaWise platform on a regular basis, you are
                  providing researchers with important insights on treating and
                  managing eczema. The more real-life experiences researchers
                  have to learn from people like you, the closer they can get to
                  finding answers, including new treatment development, more{' '}
                  <a
                    className="link link__secondary-100 link__bold"
                    href="https://nationaleczema.org/blog/personalized-medicine-eczema/"
                    rel="noreferrer"
                    target="_blank">
                    personalized medicine
                  </a>{' '}
                  and, ultimately, a cure.
                </p>
                <p className="about-paragraph about-paragraph__left">
                  Even though the data you enter into the app is helping
                  scientists do better research, your data is always private and
                  confidential. Data collected is de-identified, meaning
                  identifying personal information is not shared, and
                  researchers cannot link any data back to a particular
                  individual. Your data is also never sold for commercial
                  purposes.
                </p>
                <p className="about-paragraph about-paragraph__left">
                  By using the EczemaWise platform on a regular basis, you are
                  providing researchers with important insights on treating and
                  managing eczema. This information helps to fill gaps in our
                  understanding of how eczema affects individuals in their daily
                  lives, not just in clinical trials or in the doctor’s office.
                  It’s also an opportunity to make the research more accurate
                  and more reflective of your own experience. 
                </p>
                <br />
                <NavLink
                  className="link link__secondary-100 link__bold"
                  target="_blank"
                  to="/privacy-policy">
                  View the full EczemaWise Privacy Policy here.
                </NavLink>
              </div>
            </div>
          </section>

          <section className="content-section content-section__informational content__center">
            <div className="content__row content__space-between">
              <div className="content-about-wrapper  content-about-wrapper__left-section">
                <h2 className="about-titles about-titles__left title__secondary100">
                  Surveys
                </h2>
                <p className="about-paragraph about-paragraph__left">
                  Surveys are an important part of the EczemaWise research
                  registry. The Survey tab provides surveys regarding aspects of
                  living with eczema intended to supplement the information
                  collected in the registry.
                </p>

                <p className="about-paragraph about-paragraph__left">
                  If you have a new survey available, a notification will appear
                  in the menu so you can easily access it. The health survey
                  that appears 30 days after you sign up for EczemaWise, and
                  every year thereafter, is critical for establishing what your
                  personal baseline is so you can see how your health changes
                  year over year.
                </p>
              </div>
              <div className="images-wrapper" ref={ref}>
                <img
                  className={`image__surveys ${isVisible ? 'visible' : ''}`}
                  src={ECWSurveys}
                  alt=""
                />
              </div>
            </div>
          </section>

          <section
            id="section-page-about-advisors"
            className="content-section content-section__informational section_bg_secondary10">
            <div className="content__row content__space-between">
              <div className="images-wrapper" ref={ref}>
                <img
                  className={`image__advisors ${isVisible ? 'visible' : ''}`}
                  src={ECWAdvisors}
                  alt=""
                />
              </div>
              <div className="content-about-wrapper content-about-wrapper__right-section">
                <h2 className="about-titles about-titles__left title__secondary100">
                  EczemaWise Advisors
                </h2>

                <p className="about-paragraph">
                  NEA gratefully acknowledges patients, physicians and others
                  <br />
                  who supported the initial development of EczemaWise.
                </p>
              </div>
            </div>
          </section>

          <section className="content-section content-section__informational content__center content-section__slim section-advisors">
            <div className="content-section__inner content__column">
              <div className="content-text-wrapper">
                <h2 className="about-titles about-titles__center title__black100">
                  EczemaWise Sponsors
                </h2>

                <p className="about-paragraph about-paragraph__center">
                  Thank you to these generous sponsors for unrestricted
                  educational grants in support of EczemaWise.
                </p>

                <ul className="list images">
                  {supporters.map((supporter, i) => (
                    <li key={i} className="paragraph">
                      <img src={supporter.img} alt={supporter.name} />
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </section>
        </div>
      </FadeIn>
    </ScrollToTop>
  );
};

export default About;
