import PlatformOptions from './PlatformOptions';

function TutorialVideo() {
  return (
    <section className="home-page__video-section video-section--horizontal get-started-bg">
      <div className="content-wrapper">
        <div className="video-section">
          <iframe
            className="video-iframe"
            src="https://player.vimeo.com/video/801406590?h=4d92f409e0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen
            title="NEA_V1"></iframe>
          <script src="https://player.vimeo.com/api/player.js"></script>
        </div>
        <div className="content-section last-section">
          <h2 className="title__homepage-large title__black100">
            Get <span className="title__secondary100">started</span>
          </h2>
          <p className="paragraph">
            EczemaWise is based on science and designed with input from the
            eczema community and physicians. Start tracking today to reveal
            trends and insights you and your doctor can use to find eczema
            solutions.
          </p>
          <PlatformOptions />
        </div>
      </div>
    </section>
  );
}

export default TutorialVideo;
