import React from 'react';

// COMPONENTS
import DragScale from '../../components/shared/DragScale';

// REDUX
import { useAppDispatch, useAppSelector } from '../../store';
import { appointmentPrepActions } from '../../store/appointmentPrep';

const PrepStep2 = () => {
  const dispatch = useAppDispatch();
  const picture_self = useAppSelector(
    state => state.appointmentPrep.picture_self,
  );
  const picture_social = useAppSelector(
    state => state.appointmentPrep.picture_social,
  );
  const picture_work = useAppSelector(
    state => state.appointmentPrep.picture_work,
  );

  const onChangePictureSelf = value => {
    dispatch(appointmentPrepActions.setPictureSelf(value));
  };

  const onChangePictureSocial = value => {
    dispatch(appointmentPrepActions.setPictureSocial(value));
  };

  const onChangePictureWork = value => {
    dispatch(appointmentPrepActions.setPictureWork(value));
  };

  return (
    <div className="appointmentPrepStep">
      <div className="card">
        <div className="card-header">
          <h3>1. During the past week, how often have you been:</h3>
        </div>
        <p>
          Bothered by symptoms of your skin problem. (e.g., itching, stinging,
          burning, hurting or skin irritation)
        </p>
        <div className="thinkBigPicture">
          <div className="indications">
            <strong>0 =</strong>
            <span>Never bothered,</span>
            <strong>6 =</strong>
            <span>Always bothered</span>
          </div>
          <DragScale
            maxValue={6}
            scaleWidth={3}
            level={picture_self}
            onIncrease={() => onChangePictureSelf(picture_self + 1)}
            onDecrease={() => onChangePictureSelf(picture_self - 1)}
            onSetDirectly={index => onChangePictureSelf(index + 1)}
          />
        </div>
      </div>
      <div className="card">
        <div className="card-header">
          <h3>2. During the past week, how often have you been:</h3>
        </div>
        <p>
          Emotionally bothered by your skin problem. (e.g., worry,
          embarrassment, frustration)
        </p>
        <div className="thinkBigPicture">
          <div className="indications">
            <strong>0 =</strong>
            <span>Never bothered,</span>
            <strong>6 =</strong>
            <span>Always bothered</span>
          </div>
          <DragScale
            maxValue={6}
            scaleWidth={3}
            level={picture_social}
            onIncrease={() => onChangePictureSocial(picture_social + 1)}
            onDecrease={() => onChangePictureSocial(picture_social - 1)}
            onSetDirectly={index => onChangePictureSocial(index + 1)}
          />
        </div>
      </div>
      <div className="card">
        <div className="card-header">
          <h3>3. During the past week, how often have you been:</h3>
        </div>
        <p>
          Bothered by effects of your skin problem on your ACTIVITIES. (e.g.,
          going out, accomplishing what your want, work activities, or your
          relationships with others)
        </p>
        <div className="thinkBigPicture">
          <div className="indications">
            <strong>0 =</strong>
            <span>Never bothered,</span>
            <strong>6 =</strong>
            <span>Always bothered</span>
          </div>
          <DragScale
            maxValue={6}
            scaleWidth={3}
            level={picture_work}
            onIncrease={() => onChangePictureWork(picture_work + 1)}
            onDecrease={() => onChangePictureWork(picture_work - 1)}
            onSetDirectly={index => onChangePictureWork(index + 1)}
          />
        </div>
      </div>
      <div className="card">
        <div className="card-header">
          <p>
            Certain materials provided by Dr. M. M. CHREN, DR. R. Swerlink, and
            Dr. S. Chen, incorporated herin are Copyright 2016. The regents of
            the University of California. All Rights Reserved. SkinDex-Mini
            contact information and permission use: Mapi Research. Trush, Lyon,
            France,{' '}
            <a
              href="https://eprovide.mapi-trust.org/"
              target="_blank"
              rel="noreferrer"
              className="card-external-link">
              https://eprovide.mapi-trust.org/
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrepStep2;
