import moment from 'moment-timezone';

export const getAppointmentFormattedAddress = appointment => {
  if (!appointment.provider_address_1) {
    return 'N/A';
  }
  return `${appointment.provider_address_1}${
    appointment.provider_address_2 ? ` ${appointment.provider_address_2}` : ''
  }${appointment.provider_city ? `, ${appointment.provider_city}` : ''}${
    appointment.provider_zip ? ` ${appointment.provider_zip}` : ''
  }${appointment.provider_state ? `, ${appointment.provider_state}` : ''}`;
};

export const getUpcomingLabel = date => {
  const appointmentDate = moment(date, 'YYYY-MM-DDTHH:mm:ss[Z]');
  if (appointmentDate.isBefore(moment(), 'day')) {
    return '';
  }
  if (appointmentDate.isSame(moment(), 'day')) {
    return 'Today';
  }
  if (appointmentDate.isSame(moment().add(1, 'day'), 'day')) {
    return 'Tomorrow';
  }
  if (appointmentDate.isBefore(moment().add(1, 'week'), 'day')) {
    return `In ${appointmentDate.diff(moment().startOf('day'), 'days')} days`;
  }
  if (appointmentDate.isBefore(moment().add(2, 'week'), 'day')) {
    return 'In a week';
  }
  if (appointmentDate.isBefore(moment().add(3, 'week'), 'day')) {
    return 'In 2 weeks';
  }
  if (appointmentDate.isBefore(moment().add(4, 'week'), 'day')) {
    return 'In 3 weeks';
  }
  if (appointmentDate.isBefore(moment().add(2, 'month'), 'day')) {
    return 'In a month';
  }
  return '';
};

export const getUpcomingColor = date => {
  const appointmentDate = moment(date, 'YYYY-MM-DDTHH:mm:ss[Z]');
  if (appointmentDate.isSame(moment(), 'day')) {
    return '#e95d42';
  }
  if (appointmentDate.isBefore(moment().add(7, 'day'), 'day')) {
    return '#e95d42';
  }
  if (appointmentDate.isBefore(moment().add(1, 'month'), 'day')) {
    return '#e7eaf1';
  }
  return '#e7eaf1';
};
