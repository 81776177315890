import React, { memo } from 'react';
import moment from 'moment-timezone';
import * as Unicons from '@iconscout/react-unicons';
import { Link } from 'react-router-dom';

import MixpanelService, { MixpanelEvents } from '../../libs/mixpanel';

import {
  getAppointmentFormattedAddress,
  getUpcomingLabel,
  getUpcomingColor,
} from '../../utils/appointments';

function renderPreparationMessage(daysToCome) {
  if (daysToCome < 0) {
    return null;
  }
  if (daysToCome <= 7) {
    return (
      <div className="preparation-message">
        <div className="icon">
          <Unicons.UilLightbulbAlt size="16" />
        </div>
        <p>
          Preparation is key to success when working with your provider. Follow
          these steps to get the most out of your next appointment.
        </p>
      </div>
    );
  }
  return (
    <div className="preparation-message later">
      <div className="icon">
        <Unicons.UilLightbulbAlt size="16" />
      </div>
      <p>
        You can finish getting ready for your appointment 7 days before the
        appointment.
      </p>
    </div>
  );
}

const AppointmentCard = ({ appointment, disableEdit, onEdit, onComplete }) => {
  const date = moment(appointment.date, 'YYYY-MM-DDTHH:mm:ss[Z]');
  const time = moment(appointment.time, 'YYYY-MM-DDTHH:mm:ss[Z]');

  const labelBackground = getUpcomingColor(date);
  const textColor = labelBackground === '#e95d42' ? 'white' : '#092A6D';
  const daysToCome = date.diff(moment().startOf('day'), 'days');
  const upcomingLabel = getUpcomingLabel(date);

  const trackSummaryTapped = () => {
    MixpanelService.track(MixpanelEvents.ViewSummaryTapped, {
      AppointmentID: appointment.id,
    });
  };

  const trackGetStartedTapped = () => {
    MixpanelService.track(MixpanelEvents.GetStartedTapped, {
      AppointmentID: appointment.id,
    });
  };

  const summaryEnabled = daysToCome <= 7;

  return (
    <>
      {renderPreparationMessage(daysToCome)}
      <div className="card card-appointment">
        <div className="card-header">
          <div className="header-info">
            <div className="header-info-title">
              <h4>
                {`${appointment.provider_first_name} ${appointment.provider_last_name}`}
              </h4>
              {upcomingLabel && (
                <div
                  className="appointment-status"
                  style={{ backgroundColor: labelBackground }}>
                  <Unicons.UilClockTwo size="16" color={textColor} />
                  <p style={{ color: textColor }}>{upcomingLabel}</p>
                </div>
              )}
              {!disableEdit && (
                <div className="editButton" onClick={onEdit}>
                  <Unicons.UilPen size="16" />
                </div>
              )}
            </div>
            <p>
              {date.format('MMMM D, YYYY')} – {time.format('hh:mm A')}
            </p>
          </div>
        </div>
        <div className="card-content">
          <h4>Address: </h4>
          {appointment.provider_phone && <p>{appointment.provider_phone}</p>}
          <p>{`${getAppointmentFormattedAddress(appointment)}`}</p>
          <h4>Notes:</h4>
          <p>{appointment.notes || 'N/A'}</p>
          {appointment.metadata_added ? (
            <Link
              to={`/provider-prep/appointments/${appointment.id}/surveys`}
              onClick={trackSummaryTapped}>
              <button className="button button__secondary100-inverted">
                View Summary
              </button>
            </Link>
          ) : (
            <Link
              to={
                summaryEnabled
                  ? `/provider-prep/appointments/${appointment.id}/surveys`
                  : '/provider-prep'
              }
              onClick={summaryEnabled ? trackGetStartedTapped : null}>
              <button
                className="button button__secondary100"
                disabled={!summaryEnabled}
                onClick={onComplete}>
                Complete Summary
              </button>
            </Link>
          )}
        </div>
      </div>
    </>
  );
};

export default memo(AppointmentCard);
