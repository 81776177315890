import config from '../config';
import mixpanel from 'mixpanel-browser';

export const MixpanelEvents = {
  AppOpen: 'AppOpen',
  SignUpTapped: 'SignUpTapped',
  UserLogin: 'UserLogin',
  AccountCreated: 'AccountCreated',
  DeleteAccount: 'DeleteAccount',
  LogOutTapped: 'LogOutTapped',
  FeedbackTapped: 'FeedbackTapped',
  CreditsTapped: 'CreditsTapped',
  HelpTapped: 'HelpTapped',
  PasswordChanged: 'PasswordChanged',
  NotificationsChanged: 'NotificationsChanged', // mixpanel.track('NotificationsChanged');
  EditTreatments: 'EditTreatments',
  ZipCodeChanged: 'ZipCodeChanged',
  ArticleOpened: 'ArticleOpened',
  SearchViaHomepage: 'SearchViaHomepage',
  LearnTabTapped: 'LearnTabTapped',
  ManageTabTapped: 'ManageTabTapped',
  SurveyTabTapped: 'SurveyTabTapped',
  SurveySelected: 'SurveySelected',
  SurveyCompleted: 'SurveyCompleted', // mixpanel.track('SurveyCompleted');
  GetStartedTapped: 'GetStartedTapped',
  AppointmentCreated: 'AppointmentCreated',
  AppointmentEdited: 'AppointmentEdited',
  ViewMoreDetailsTapped: 'ViewMoreDetailsTapped',
  ViewSummaryTapped: 'ViewSummaryTapped',
  DownloadSummaryTapped: 'DownloadSummaryTapped',
  DietTrackerUpdated: 'DietTrackerUpdated',
  TreatmentTrackerUpdated: 'TreatmentTrackerUpdated',
  SleepTrackerUpdated: 'SleepTrackerUpdated',
  PainTrackerUpdated: 'PainTrackerUpdated',
  ItchTrackerUpdated: 'ItchTrackerUpdated',
  SkinTrackerUpdated: 'SkinTrackerUpdated',
  StressTrackerUpdated: 'StressTrackerUpdated',
  TriggerTrackerUpdated: 'TriggerTrackerUpdated',
  AppShared: 'AppShared',
  TrackTabTapped: 'TrackTabTapped',
  NewsTabTapped: 'NewsTabTapped',
  SettingsCogTapped: 'SettingsCogTapped',
  InstagramTaps: 'InstagramTaps',
  RegistrationCompleted: 'RegistrationCompleted',
  TotalPageViewsHCP: 'TotalPageViewsHCP',
  PageTappedHCP: 'PageTappedHCP',
  EczemaWiseLogoTappedHCP: 'EczemaWiseLogoTappedHCP',
  LogInTappedHCP: 'LogInTappedHCP',
  SignUpTappedHCP: 'SignUpTappedHCP',
  DownloadAppStoreTappedHCP: 'DownloadAppStoreTappedHCP',
  DownloadGooglePlayTappedHCP: 'DownloadGooglePlayTappedHCP',
  GuideDownloadTappedHCP: 'GuideDownloadTappedHCP',
  PageProgressHCP: 'PageProgressHCP'
};

export const MIXPANEL_DAY_FORMAT = 'YYYY-MM-DD';
export const MIXPANEL_TIME_FORMAT = 'HH:MM:SS';

class MixpanelService {
  static async configure() {
    this.mixpanel = mixpanel;
    this.mixpanel.init(config.mixpanelToken);
  }

  static async identify(id) {
    if (id) {
      this.mixpanel.identify(id);
      this.mixpanel.register({ UserId: id });
      this.mixpanel.people.set({ 'UserId': id });
    }
  }

  static async reset() {
    this.mixpanel.reset();
  }

  static registerSuperProps(props) {
    if (process.env.NODE_ENV === 'development') console.log('Super Properties: ', props);
    this.mixpanel.register({ ...props });
  }

  static setProfileProperty(propKey, propValue) {
    if (process.env.NODE_ENV === 'development') console.log('Profile Property: ', propKey, propValue);
    this.mixpanel.people.set(propKey, propValue);
  }

  static setIncrementProfileProperty(propKey, propValue) {
    if (process.env.NODE_ENV === 'development') console.log('Profile Property: ', propKey, propValue);
    this.mixpanel.people.increment(propKey, propValue);
  }

  static track(eventName, properties) {
    if (process.env.NODE_ENV === 'development') console.log(eventName, properties);
    if (this.mixpanel) {
      this.mixpanel.track(eventName, properties);
    }
  }

  static trackPageView(pageName) {
    if (process.env.NODE_ENV === 'development') console.log('PageView', pageName);
    if (this.mixpanel) {
      this.mixpanel.track_pageview({
        page: pageName,
      })
    }
  }
}

export default MixpanelService;
