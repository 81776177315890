import React, { useRef } from 'react';
import * as Unicons from '@iconscout/react-unicons';
import ResourceHubHero1 from '../../../assets/images/hcp-landing/resource-hub-hero1.svg';
import ResourceHubHero2 from '../../../assets/images/hcp-landing/resource-hub-hero2.svg';
import { useFirstTimeVisible } from '../../../components/shared/hooks/useFirstTimeVisible';

const ResourceHub = ({ onSectionIsVisible }) => {
  const ref = useRef();
  const isVisible = useFirstTimeVisible(ref, onSectionIsVisible);

  return (
    <div className="resource-hub">
      <div className="resource-hub-info">
        <h3>
          Visit the National Eczema Association’s HCP Resource Hub for more
          resources for your patients and practice.
        </h3>
        <a
          href="https://nationaleczema.org/hcp-resource-hub/"
          target="_blank"
          rel="noreferrer"
          ref={ref}>
          <button>
            <Unicons.UilArrowRight size="16" />
            Learn more
          </button>
        </a>
      </div>
      <div className="resource-hub-hero">
        <img src={ResourceHubHero1} alt="" />
        <img src={ResourceHubHero2} alt="" className="hero2" />
      </div>
    </div>
  );
};

export default ResourceHub;
