import React, { useEffect, useState } from 'react';

// ICONS
import { UserBlueIcon } from '../../assets/icons';
import * as Unicons from '@iconscout/react-unicons';

// COMPONENTS
import { AppointmentCard, GetReady } from '../../components/manage';
import AppointmentForm from '../../components/forms/AppointmentForm';

// APOLLO
import { useQuery } from 'react-apollo';
import { UserAppointments } from '../../libs/graphql/queries/user';

// REDUX
import { useAppDispatch, useAppSelector } from '../../store';
import { appointmentActions } from '../../store/appointments';

const Manage = ({ me }) => {
  const dispatch = useAppDispatch();
  const upcomingAppointments = useAppSelector(
    state => state.appointments.upcomingAppointments,
  );
  const pastAppointments = useAppSelector(
    state => state.appointments.pastAppointments,
  );

  // STATES
  const [showCreate, setShowCreate] = useState(false);
  const [selectedTab, setSelectedTab] = useState('upcoming');
  const [appointmentToEdit, setAppointmentToEdit] = useState(null);

  // Queries for appointments
  const { data, refetch } = useQuery(UserAppointments, {
    variables: { userid: me.id },
  });

  useEffect(() => {
    dispatch(
      appointmentActions.setAppointments(data?.getAppointmentsByUserId || []),
    );
  }, [dispatch, data]);

  const onAddAppointment = () => {
    setShowCreate(false);
    refetch();
  };

  const addAppointment = () => {
    setAppointmentToEdit(null);
    setShowCreate(true);
  };

  const onDeleteAppointment = appointment => {
    refetch();
  };

  const onEditAppointmentPress = appointment => {
    setAppointmentToEdit(appointment);
    setShowCreate(true);
  };

  const onCancelEditAppointment = () => {
    setShowCreate(false);
    setAppointmentToEdit(null);
  };

  return (
    <div className="main-dashboard page-provider-prep">
      <div className="provider-prep-container">
        <header>
          <div className="date-bar date-bar__provider-prep">
            <div className="main-dashboard__sidebar">
              {showCreate ? (
                <div className="sidebar-title">
                  <Unicons.UilTimes
                    size="24"
                    color="#000"
                    onClick={() => {
                      setShowCreate(false);
                    }}
                  />
                  <h1 className="headers headers__black100 headers__mediumbold">
                    Create new appointment
                  </h1>
                </div>
              ) : (
                <div className="sidebar-title">
                  <UserBlueIcon />
                  <h1 className="headers headers__black100 headers__uppercase headers__mediumbold">
                    Provider Prep
                  </h1>
                </div>
              )}
            </div>
            <div className="header-buttons">
              {showCreate ? (
                <button
                  className="button button__secondary100-inverted button__btn-14-600 button-rectangle-medium buttonCancel"
                  onClick={() => {
                    setShowCreate(false);
                  }}>
                  Cancel
                </button>
              ) : (
                <button
                  className="button button__secondary100 button__btn-14-600 button-rectangle-medium buttonCancel"
                  onClick={addAppointment}>
                  Create Appointment
                </button>
              )}
            </div>
          </div>
        </header>
        {!showCreate && (
          <div className="manage-container">
            <div className="manage-content">
              <div className="tabs">
                <div
                  className={`tab ${
                    selectedTab === 'upcoming' ? 'active' : ''
                  }`}
                  onClick={() => setSelectedTab('upcoming')}>
                  Upcoming
                </div>
                <div
                  className={`tab ${selectedTab === 'past' ? 'active' : ''}`}
                  onClick={() => setSelectedTab('past')}>
                  Past
                </div>
              </div>
              <div className="manage-cards">
                {selectedTab === 'upcoming' && (
                  <GetReady
                    upcomingAppointments={upcomingAppointments.length}
                  />
                )}
                {selectedTab === 'upcoming' &&
                  upcomingAppointments.map(appointment => (
                    <AppointmentCard
                      appointment={appointment}
                      key={appointment.id}
                      onEdit={() => onEditAppointmentPress(appointment)}
                    />
                  ))}

                {selectedTab === 'past' &&
                  pastAppointments.map(appointment => (
                    <AppointmentCard
                      appointment={appointment}
                      key={appointment.id}
                      disableEdit={true}
                      onEdit={() => onEditAppointmentPress(appointment)}
                    />
                  ))}
              </div>
            </div>
          </div>
        )}
        {showCreate && (
          <AppointmentForm
            userid={me.id}
            appointmentToEdit={appointmentToEdit}
            onCancel={onCancelEditAppointment}
            onAddAppointment={onAddAppointment}
            onDeleteAppointment={onDeleteAppointment}
          />
        )}
      </div>
    </div>
  );
};

export default Manage;
