import React from 'react';

// COMPONENTS
import { TreatmentsSelection } from './components';

// REDUX
import { useAppDispatch, useAppSelector } from '../../store';
import { appointmentPrepActions } from '../../store/appointmentPrep';

const PrepStep3 = () => {
  const dispatch = useAppDispatch();
  const selectedTreatmentsIds = useAppSelector(
    state => state.appointmentPrep.selectedTreatmentsIds,
  );

  const onAddTreatment = treatment => {
    const { id, name } = treatment;
    dispatch(appointmentPrepActions.toggleTreatment({ id, name }));
  };

  return (
    <div className="appointmentPrepStep">
      <div className="card descriptions">
        <h3>Learn about treatments</h3>
        <p>
          Learn more about the available treatments for eczema. This tool will
          help you to make informed decisions about your care with your provider
        </p>
      </div>
      <TreatmentsSelection
        selectedTreatments={selectedTreatmentsIds}
        onAddTreatment={onAddTreatment}
        editable={true}
      />
    </div>
  );
};

export default PrepStep3;
